import { Component } from '@angular/core';
import { RouterModule } from '@angular/router';
import { HomeService } from '../../pages/home/service/home.service';
import { CommonModule } from '@angular/common';
import { SettingsService } from '../../services/settings.service';

@Component({
  selector: 'app-footer',
  standalone: true,
  imports: [RouterModule,CommonModule],
  templateUrl: './footer.component.html',
  styleUrl: './footer.component.css'
})
export class FooterComponent {
  footer:any=[];
  support:any=[];
  settings:any=null;
  constructor(
    private homeService: HomeService,
    private settingsService: SettingsService
  ){}
  ngOnInit(): void {
    //Called after the constructor, initializing input properties, and the first call to ngOnChanges.
    //Add 'implements OnInit' to the class.
    this.homeService.footer().subscribe((resp:any) =>{
      this.footer=resp.footer;
      this.support=resp.support;
    })
    this.settingsService.settings$.subscribe(
      (data) => {
        this.settings = data;
      },
      (error) => {
        console.error('Error fetching settings:', error);
      }
    );
  }
}
