import { Component } from '@angular/core';
import { CodeForgotPasswordComponent } from '../code-forgot-password/code-forgot-password.component';
import { NewPasswordComponent } from '../new-password/new-password.component';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from '../service/auth.service';
import { Router, RouterModule } from '@angular/router';

@Component({
  selector: 'app-forgot-password',
  standalone: true,
  imports: [CodeForgotPasswordComponent, NewPasswordComponent, CommonModule, FormsModule, RouterModule],
  templateUrl: './forgot-password.component.html',
  styleUrl: './forgot-password.component.css'
})
export class ForgotPasswordComponent {
  
  isLoadingMail:any = null;
  isLoadingCode:any = null;
  loadingCodeStatus:any=null;

  email: string = '';

  constructor(
    private toastr: ToastrService,
    private authService: AuthService,
    private router: Router
  ){}

  verifiedMail(){
    if(!this.email){
      this.toastr.error("Lỗi","Vui lòng nhập email tài khoản");
      return false;
    }
    let data={
      email: this.email,
    }
    this.authService.verifiedMail(data).subscribe((resp:any)=>{
      console.log(resp);
      if(resp.code==200){
        this.isLoadingMail=1;
        this.toastr.success("Thông báo",resp.message);
      }else{
        this.isLoadingMail=null;
        this.toastr.error("Lỗi",resp.message);
      }
    }, (error)=>{
      //console.log(error);
      this.toastr.error("Lỗi", error.error.message)
    })
    return true;
  }
  loadingCode(event:any){
    this.isLoadingCode=event;
    console.log(this.isLoadingCode, this.isLoadingMail);
  }
}
