<main class=" py-3">
    <div class="container">
        <section class="breadcrumb__area include-bg pt-5 pb-5">
            <div class="container">
               <div class="breadcrumb__content position-relative z-index-1">
                  <div class="row">
                     <div class="col-md-6 col-12"><h1 class="breadcrumb__title">Sản phẩm</h1></div>
                     <div class="col-md-6 col-12 text-md-end text-start">
                        <div class="breadcrumb__list">
                           <span><a routerLink="/">Trang chủ</a></span>
                           <span>Sản phẩm</span>
                        </div>
                     </div>
                  </div>
               </div>
               
            </div>
        </section>
        <div class="row">
            <div class="col-md-3 col-12">
                <!-- <div class="tp-shop-widget mb-5">
                    <h3 class="tp-shop-widget-title no-border">Lọc theo giá</h3>
                     <div class="tp-shop-widget-content ">
                        <div *ngIf="sliderKey" class="slider-range position-relative">
                           <div id="{{ 'slider-' + sliderKey }}" class="slider-track"></div>
                           <input
                             type="range"
                             min="{{ minLimit }}"
                             max="{{ maxLimit }}"
                             step="10000"
                             [(ngModel)]="minValue"
                             (ngModelChange)="valueChanged(sliderKey)"
                           />
                           <input
                             type="range"
                             min="{{ minLimit }}"
                             max="{{ maxLimit }}"
                             [(ngModel)]="maxValue"
                             step="10000"
                             (ngModelChange)="valueChanged(sliderKey)"
                           />
                        </div>
                        <div class="tp-shop-widget-filter">
                            <div id="slider-range" class="mb-3" #sliderRange></div>
                          <div class="tp-shop-widget-filter-info d-flex align-items-center justify-content-between">
                             <span class="input-range fw-bold">
                              {{ minValue | number:'1.0-0' }}đ - {{ maxValue | number:'1.0-0' }}đ
                             </span>
                             <button class="tp-shop-widget-filter-btn btn btn-primary" type="button" (click)="onPriceRangeChange($event)">Lọc</button>
                          </div>
                       </div>
                    </div>
                 </div> -->
                 <app-left-product></app-left-product>
            </div>
            <div class="col-md-9 col-12">
                <div class="grid_container">
                    <div class="columns-md-3 columns-xs-6 mb-4 item_product" *ngFor="let product of products">
                        <div class="sale" *ngIf="product.sale">{{ product.sale }}%</div>
                        <div class="images">
                           <a routerLink="/san-pham/{{ product.slug }}-tjk{{ product.id }}">
                              <img *ngIf="product.image; else usePhoto" [src]="product.image.thumbnail" alt="{{product.name}}" class="img-fluid">
                              <!-- Nếu không có thumbnail thì hiển thị ảnh photo -->
                              <ng-template #usePhoto>
                              <img src="assets/images/no-image.jpg" alt="{{product.name}}" class="img-fluid">
                              </ng-template>
                           </a>
                        </div>
                        <div class="info p-4 py-2">
                        <div class="tp-product-category"><a routerLink="{{ product.categorie_first.slug }}-c{{ product.categorie_first.id }}">{{ product.categorie_first.name }}</a></div>
                        <a routerLink="/san-pham/{{ product.slug }}-tjk{{ product.id }}">
                              <h3>{{ product.name }}</h3>
                           </a>
                           <div class="tp-product-rating-icon">
                           <i class="fas fa-star"></i>
                           <i class="fas fa-star"></i>
                           <i class="fas fa-star"></i>
                           <i class="fas fa-star"></i>
                           <i class="fas fa-star"></i>
                        </div>
                        <div class="price">
                              <span *ngIf="product.price" class="me-3">{{ product.price | number:'1.0-0' }} đ</span>
                              <strong *ngIf="product.priceSale > 0">
                                 {{ product.priceSale | number:'1.0-0' }} đ
                               </strong>
                               <strong *ngIf="product.priceSale <= 0">
                                 Liên hệ
                               </strong>
                           </div>
                        </div>
                    </div>
                </div>
                  <ngb-pagination *ngIf="totalPages/20>1"
                     [collectionSize]="totalPages"
                     [(page)]="currentPage"
                     [pageSize]="20"
                     [rotate]="true"
                     [boundaryLinks]="true"
                     (pageChange)="loadPage($event)"
                     [maxSize]="5"
               ></ngb-pagination>
            </div>
        </div>
    </div>
</main>