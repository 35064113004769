<div *ngIf="!isAuthenticated; else websiteContent">
    <div class="container">
        <div class=" d-flex align-items-center justify-content-center" style="height: 100vh;">
            <div class="p-3">
                <h3 class="mb-3">Website đang xây dựng, vui lòng trở lại sau. Xin cảm ơn.</h3>
                <div class="d-flex">
                    <input type="text" class="form-control" [(ngModel)]="codeInput" placeholder="Nhập mã code" name="code" required />
                    <button class="btn btn-success" (click)="checkCode()" style="white-space: nowrap;">Xác nhận</button>
                </div>
                <p *ngIf="errorMessage" class="text-danger">{{ errorMessage }}</p>
            </div>
        </div>
    </div>
  </div>
  
  <ng-template #websiteContent>
    <app-header></app-header>
    <router-outlet></router-outlet>
    <app-footer></app-footer>
  </ng-template>
