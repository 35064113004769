import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { RouterModule, ActivatedRoute, Router } from '@angular/router';
import { NewsService } from '../service/news.service';
import { ImagesLazyloadModule } from '../../../shared/images-lazyload/images-lazyload.module';

@Component({
  selector: 'app-detail-news',
  standalone: true,
  imports: [RouterModule, CommonModule,ImagesLazyloadModule],
  templateUrl: './detail-news.component.html',
  styleUrl: './detail-news.component.css'
})
export class DetailNewsComponent {
  title:string='Giới thiệu';
  settings:any=[];
  news:any=[];
  newsOther:any=[];
  type:string='';
  articleSlug:string='';
  categorySlug:string='';
  isNotFound: boolean = false;
  com:string='';
  article_ID:string='';

  constructor(
    private activedRoute: ActivatedRoute,
    private newsService: NewsService,
    public router: Router,
  ){}
  ngOnInit(): void {
    this.activedRoute.params.subscribe((resp:any)=>{
      this.categorySlug = resp.slugNewCategory; // Phần đầu là slug
      this.articleSlug = resp.slugNew;
      this.article_ID = resp.idTintuc;
      this.detailBlogs();
    })
    this.activedRoute.data.subscribe(data => {
      this.type = data['type'];
      this.com = data['com'];
    });
    
  }

  detailBlogs(){
    this.newsService.getNewsDetail(this.categorySlug,this.articleSlug).subscribe((resp:any)=>{
      if(resp.newsDetail){
        this.news=resp.newsDetail;
        this.newsOther=resp.newsOther;
      }else{
        this.isNotFound = true;
      }
    },(error)=>{
      this.isNotFound = true;
    })
  }
}
