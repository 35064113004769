import { Component, AfterViewInit, ChangeDetectorRef, PLATFORM_ID, Inject, Renderer2 } from '@angular/core';
import { SliderComponent } from '../../shared/slider/slider.component';
import { ActivatedRoute, RouterModule } from '@angular/router';
import { HomeService } from './service/home.service';
import { CommonModule, isPlatformBrowser } from '@angular/common';
import { SettingsService } from '../../services/settings.service';
import { Title, Meta } from '@angular/platform-browser';
import { ImagesLazyloadModule } from '../../shared/images-lazyload/images-lazyload.module';
declare var $:any;
declare function homeInit([]):any;

@Component({
  selector: 'app-home',
  standalone: true,
  imports: [SliderComponent, RouterModule, CommonModule, ImagesLazyloadModule],
  templateUrl: './home.component.html',
  styleUrl: './home.component.css'
})
export class HomeComponent implements AfterViewInit {
  categories:any=[];
  productSale:any=[];
  settings: any = null;
  schema: string ='';
  
  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    private homeService: HomeService,
    private settingsService: SettingsService,
    private cdr: ChangeDetectorRef,
    private titleService: Title,
    private metaService: Meta,
    private renderer: Renderer2
  ){}
  
  ngAfterViewInit(): void {
    
  }
  ngOnInit(): void {

    this.homeService.home().subscribe((resp:any) =>{
      this.categories=resp.categories;
      this.productSale=resp.productSale;
      
      if (isPlatformBrowser(this.platformId)) {
        setTimeout(()=>{
          homeInit($);
        },500)
      }
      this.cdr.detectChanges();
    });
    this.settingsService.settings$.subscribe(
      (data) => {
        this.settings = data;
        if (this.settings && this.settings.title) {
          this.updateMetaTags();
        }
        
        this.cdr.detectChanges();
      },
      (error) => {
        console.error('Error fetching settings:', error);
      }
    );
  }
  
  private updateMetaTags(): void {
    if (isPlatformBrowser(this.platformId)) {
      this.titleService.setTitle(this.settings.title);

      this.metaService.updateTag({ name: 'description', content: this.settings.description });
      this.metaService.updateTag({ property: 'og:title', content: this.settings.title });
      this.metaService.updateTag({ property: 'og:site_name', content: this.settings.title });
      this.metaService.updateTag({ property: 'og:description', content: this.settings.description });
      this.metaService.updateTag({ property: 'og:url', content: window.location.href });
      this.metaService.updateTag({ property: 'og:image', content: this.settings.logo });
      this.metaService.updateTag({ name: 'keywords', content: this.settings.keywords });
    }
  }
}
