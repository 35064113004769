<main class="bg-gray-100 py-3">
    <div class="container bg-white p-3">
        <div class="content1x postDetail" *ngIf="!isNotFound; else notFoundTemplate">
            <h1 class="title">{{ news.name }}</h1>
            <div class="date">
                <i class="fas fa-calendar-alt"></i> {{ news.created_at }}
            </div>
            <div class="my-3" [innerHTML]="news.content"></div>
            <div class="mt-3 ortherNews">
                <h3 class="title_other">Bài viết liên quan</h3>
                <div class="tp-blog-item mb-3 grid_container" *ngFor="let item of newsOther">
                    <div class="tp-blog-thumb p-relative fix columns-md-3 columns-xs-12 mb-3">
                        <a [routerLink]="['/', item.news_categories.slug, item.slug+ '-p' + item.id+'.html']">
                            <img *ngIf="item.image; else usePhoto" [src]="item.image.thumbnail" alt="{{item.name}}" class="img-fluid">
                            <!-- Nếu không có thumbnail thì hiển thị ảnh photo -->
                            <ng-template #usePhoto>
                                <img src="assets/images/no-image.jpg" alt="{{item.name}}" class="img-fluid">
                            </ng-template>
                        </a>
                    </div>
                    <div class="tp-blog-content columns-md-9 columns-xs-12">
                        <h3 class="tp-blog-title">
                            <a [routerLink]="['/', item.news_categories.slug, item.slug+ '-p' + item.id+'.html']">{{item.name}}</a>
                        </h3>
                        <div class="tp-blog-meta-date"><i class="fas fa-calendar-alt"></i> {{ item.created_at }}</div>
                        <p>{{item.mota}}</p>
                        <div class="tp-blog-btn">
                            <a [routerLink]="['/', item.news_categories.slug, item.slug+ '-p' + item.id+'.html']" class="tp-btn-2 tp-btn-border-2">
                            Xem thêm 
                            <span>
                                <svg width="17" height="15" viewBox="0 0 17 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M16 7.5L1 7.5" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                                    <path d="M9.9502 1.47541L16.0002 7.49941L9.9502 13.5244" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                                </svg>                                       
                            </span>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- Template hiển thị trang 404 -->
        <ng-template #notFoundTemplate>
            <div id="notfound">
                <div class="notfound">
                    <div class="notfound-404">
                        <h1>404!</h1>
                    </div>
                    <p>Xin lỗi, trang bạn tìm kiếm không tồn tại </p>
                    <div class="text-center"><a routerLink="/" class="btn btn-primary">Quay về trang chủ</a></div>
                </div>
            </div>
        </ng-template>
    </div>
</main>