import { CommonModule, isPlatformBrowser } from '@angular/common';
import { Component, Inject, PLATFORM_ID } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { CartService } from '../../services/cart.service';
import { Title, Meta } from '@angular/platform-browser';
import { URL_WEBSITE } from '../../config/config';
import { SettingsService } from '../../services/settings.service';
import { error } from 'console';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-order-confirm',
  standalone: true,
  imports: [CommonModule,FormsModule, RouterModule],
  templateUrl: './order-confirm.component.html',
  styleUrl: './order-confirm.component.css'
})
export class OrderConfirmComponent {
  orderID:string='';
  order:any=[];
  orderDetail:any=[];
  title:string='';
  settings:any=[];
  constructor(
    private cartService: CartService,
    public router: Router,
    public activedRoute: ActivatedRoute,
    @Inject(PLATFORM_ID) private platformId: Object,
    private titleService: Title,
    private metaService: Meta,
    private settingsService: SettingsService,
    private toastr: ToastrService
  ) {
    this.activedRoute.params.subscribe((resp:any) => {
      this.orderID = resp.orderID;
      this.title="Thông tin đơn hàng #"+this.orderID;
    })
  }
  ngOnInit(): void {
    //Called after the constructor, initializing input properties, and the first call to ngOnChanges.
    //Add 'implements OnInit' to the class.
    this.settingsService.settings$.subscribe(
      (data) => {
        this.settings = data;
        this.updateMetaTags();
      },
      (error) => {
        console.error('Error fetching settings:', error);
      }
    );
    this.getOrder();
  }
  getOrder(){
    this.cartService.getOrderCode(this.orderID).subscribe((resp:any) =>{console.log(resp);
      this.order=resp.order;
      this.orderDetail=resp.order_details;
    }, (error)=>{
      this.toastr.error("Lỗi", error.error.message);
      setTimeout(() => {
          this.router.navigateByUrl("/");
        }, 1000);
      return;
    })
  }
  private updateMetaTags(): void {
    if (isPlatformBrowser(this.platformId)) {
      const title = this.title || this.settings.title;
      const description = this.settings.description;
      const imageUrl = this.settings.photo;

      this.titleService.setTitle(title+ " - "+URL_WEBSITE);
      this.metaService.updateTag({ name: 'description', content: description });
      this.metaService.updateTag({ property: 'og:title', content: title });
      this.metaService.updateTag({ property: 'og:description', content: description });
      this.metaService.updateTag({ property: 'og:url', content: window.location.href });
      this.metaService.updateTag({ property: 'og:image', content: imageUrl });
      this.metaService.updateTag({ name: 'keywords', content:this.settings.keywords });
    }
  }
}
