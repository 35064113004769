<main class="bg-gray-100 py-3">
    <div class="container">
        <h1 class="title_page">{{title}}</h1>
        <div class="content" *ngIf="news">
            <div class="row">
                <div class="col-md-9 col-12">
                    <div class="tp-blog-item mb-3 row" *ngFor="let item of news">
                        <div class="tp-blog-thumb p-relative fix mb-2 col-md-3 col-12">
                            <a [routerLink]="['/', item.news_categories.slug, item.slug+ '-p' + item.id+'.html']">
                                <img *ngIf="item.image; else usePhoto" [src]="item.image.thumbnail" alt="{{item.name}}" class="img-fluid">
                                <!-- Nếu không có thumbnail thì hiển thị ảnh photo -->
                                <ng-template #usePhoto>
                                    <img src="assets/images/no-image.jpg" alt="{{item.name}}" class="img-fluid">
                                </ng-template>
                            </a>
                        </div>
                        <div class="tp-blog-content col-md-9 col-12">
                            <h3 class="tp-blog-title">
                                <a [routerLink]="['/', item.news_categories.slug, item.slug+ '-p' + item.id+'.html']">{{item.name}}</a>
                            </h3>
                            <div class="tp-blog-meta-date"><i class="fas fa-calendar-alt"></i> {{ item.created_at }}</div>
                            <p>{{item.mota}}</p>
                            <div class="tp-blog-btn">
                                <a [routerLink]="['/', item.news_categories.slug, item.slug+ '-p' + item.id+'.html']" class="tp-btn-2 tp-btn-border-2">
                                Xem thêm 
                                <span>
                                    <svg width="17" height="15" viewBox="0 0 17 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M16 7.5L1 7.5" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                                        <path d="M9.9502 1.47541L16.0002 7.49941L9.9502 13.5244" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                                    </svg>                                       
                                </span>
                                </a>
                            </div>
                        </div>                    
                    </div>
                    <ngb-pagination *ngIf="totalPages/16>1"
                        [collectionSize]="totalPages"
                        [(page)]="currentPage"
                        [pageSize]="16"
                        [rotate]="true"
                        [boundaryLinks]="true"
                        (pageChange)="loadPage($event)"
                        [maxSize]="5"
                    ></ngb-pagination>
                    <div class="contentDetail mh-200 bg-white p-3 my-3" *ngIf="categorie.content" [ngStyle]="{'height': isContentExpanded ? 'auto' : '200px'}">
                        <div class="content" [innerHTML]="categorie.content"></div>
                        <div class="viewMore">
                            <a class="btn btn_readmore ripple-surface" (click)="showMore()" [class.active]="isContentExpanded"><span>{{ isContentExpanded ? 'Rút gọn' : 'Xem thêm' }}</span> <svg class="svg svg-more-down ml-3" xmlns="http://www.w3.org/2000/svg" width="9" height="6" viewBox="0 0 9 6"><path fill="none" fill-rule="evenodd" stroke="#08711c" stroke-linecap="round" stroke-width="2" d="M7.5 1.5l-3 3-3-3"></path></svg></a>
                        </div>
                    </div>
                </div>
                <div class="col-md-3 col-12"><app-left-product></app-left-product></div>
            </div>
        </div>
        
    </div>
</main>
