import { Component } from '@angular/core';
import { ProductService } from '../../service/product.service';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { ImagesLazyloadModule } from '../../../../shared/images-lazyload/images-lazyload.module';

@Component({
  selector: 'app-left-product',
  standalone: true,
  imports: [RouterModule,CommonModule,ImagesLazyloadModule],
  templateUrl: './left-product.component.html',
  styleUrl: './left-product.component.css'
})
export class LeftProductComponent {
  categories:any=[];
  productSale:any=[];
  constructor(
    private productService: ProductService
  ){}

  ngOnInit(): void {
    //Called after the constructor, initializing input properties, and the first call to ngOnChanges.
    //Add 'implements OnInit' to the class.
    this.getCategories();
  }
  getCategories(){
    this.productService.getCategories().subscribe((resp:any) =>{
      this.categories=resp.categories;
      this.productSale=resp.productSale;
    })
  }
}
