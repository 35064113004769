import { ChangeDetectorRef, Component, Inject, PLATFORM_ID } from '@angular/core';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { SettingsService } from '../../../services/settings.service';
import { BrandService } from '../../brand/service/brand.service';
import { ProductService } from '../service/product.service';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { NgbPaginationModule } from '@ng-bootstrap/ng-bootstrap';
import { ImagesLazyloadModule } from '../../../shared/images-lazyload/images-lazyload.module';
import { LeftProductComponent } from '../layout/left-product/left-product.component';
import { error } from 'console';

@Component({
  selector: 'app-brand-categorie',
  standalone: true,
  imports: [CommonModule, FormsModule,NgbPaginationModule, RouterModule, LeftProductComponent, ImagesLazyloadModule ],
  templateUrl: './brand-categorie.component.html',
  styleUrl: './brand-categorie.component.css'
})
export class BrandCategorieComponent {
  settings: any = [];
  products: any[] = [];
  totalPages:number = 0;
  currentPage:number = 1;
  prices: any[] = [];
  brands: any[] = [];
  selectedBrand: any=[];
  brand_id: any='';
  slugBrand:string='';

  minValue: number = 50;
  maxValue: number = 80;
  maxLimit:number= 1000000;
  minLimit:number= 0;
  priceFilter:string='';

  sliderKey!: string;
  categorie_slug:string='';
  categorie:any=[];
  isContentExpanded: boolean = false;

  
  constructor(
    private productService: ProductService,
    private brandService: BrandService,
    private cdr: ChangeDetectorRef,
    public activedRoute: ActivatedRoute,
    private router: Router,
    private toastr: ToastrService,
    private settingsService: SettingsService,
  ){}

  ngAfterViewInit() {
    this.cdr.detectChanges();
  }
  ngOnInit(): void {
    this.activedRoute.params.subscribe((resp:any) => {
      this.categorie_slug=resp.slugCategorie;
      this.slugBrand=resp.slugBrand;

      this.productService.getCategoriesBrand(this.categorie_slug, this.slugBrand).subscribe((resp:any) => {
        this.categorie=resp.categorie;
        this.selectedBrand=resp.brand;
        this.brand_id=resp.brand.id;
        this.getProducts(this.currentPage);
      },(error) => {
        this.router.navigate(['/404'])
      })
    })
    this.activedRoute.queryParams.subscribe(queryParams => {
      this.priceFilter = queryParams['p'];
      this.currentPage = queryParams['page'] ? +queryParams['page'] : 1;
      
      if(this.priceFilter){this.getProducts(this.currentPage);}
    });
    //Lấy danh sách giá search
    this.productService.getPriceSearchs().subscribe((resp:any) =>{
      this.prices=resp.prices;
      this.brands=resp.brands;
    })
    // this.brandService.getAllBrand(this.currentPage).subscribe((resp:any) =>{
    //   this.brands=resp.brands;
    // })
  }
  showMore() {
    this.isContentExpanded = !this.isContentExpanded; // Đảo trạng thái
  }
  

  onPageChange(page: number): void {
    this.currentPage = page;
    this.getProducts(this.currentPage);
  }

  getProducts(page=1): void {
    this.productService.getProductsCategorie(page, this.categorie.id, this.categorie.type_categorie, this.priceFilter, this.brand_id).subscribe((data: any) => {
      this.products = data.products;
      this.totalPages = data.total;
    });
  }
  
  loadPage(page: number): void {
    this.currentPage = page;
  
    this.router.navigate([], {
      relativeTo: this.activedRoute,
      queryParams: { page: this.currentPage },
      queryParamsHandling: 'merge'
    });
    this.getProducts(page);
  }
  // onPriceRangeChange(event: any): void {
  //   this.filtterProducts();
  // }
  // filtterProducts(page=1): void {
  //   this.productService.filtterProductsCategorie(this.minValue, this.maxValue, page, this.categorie.id, this.categorie.type_categorie).subscribe((data: any) => {
  //     this.products = data.products;
  //     this.totalPages = data.total;
  //   });
  // }
  // generateUniqueId(): string {
  //   const dateString = Date.now().toString(36);
  //   const randomness = Math.random().toString(36).substr(2);
  //   return dateString + randomness;
  // }

  // valueChanged(sliderKey: string) {
  //   this.fillColor(this.minValue, this.maxValue, sliderKey);
  // }

  // fillColor(minInput: number, maxInput: number, id: string) {
  //   let minInputValue: number;
  //   let maxInputValue: number;

  //   let totalLimit = Math.abs(this.minLimit) + Math.abs(this.maxLimit);

  //   if (minInput < 0) {
  //     minInputValue = Math.abs(this.minLimit) - Math.abs(minInput);
  //   } else {
  //     minInputValue = Math.abs(this.minLimit) + minInput;
  //   }

  //   if (maxInput >= 0) {
  //     maxInputValue = Math.abs(this.minLimit) + maxInput;
  //   } else {
  //     maxInputValue = Math.abs(this.minLimit) - Math.abs(maxInput);
  //   }
  //   if (id !== null || id !== undefined) {
  //     let percent1 = (minInputValue / totalLimit) * 100;
  //     let percent2 = (maxInputValue / totalLimit) * 100;
  //     let sliderTrack: any = document.querySelector('#slider-' + id);
  //     sliderTrack.style.background = `linear-gradient(to right, #dadae5 ${percent1}% , #3264fe ${percent1}% , #3264fe ${percent2}%, #dadae5 ${percent2}%)`;
  //   }
  // }
}
