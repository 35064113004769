<header>
   <div class="container">
      
      <div class="d-md-flex flex-wrap justify-content-between top-header d-none">
         <div class="d-flex hotline" *ngIf="settings">
            <span><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="none" viewBox="0 0 20 20"><path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.2" d="M4.167 3.333H7.5L9.167 7.5 7.083 8.75a9.166 9.166 0 004.167 4.166l1.25-2.083 4.167 1.667v3.333A1.666 1.666 0 0115 17.5 13.334 13.334 0 012.5 5a1.667 1.667 0 011.667-1.667M12.5 5.833A1.667 1.667 0 0114.167 7.5M12.5 2.5a5 5 0 015 5"></path></svg></span>
            <a *ngIf="settings.hotline" href="tel:{{ settings.hotline }}">{{ settings.hotline }}</a>
         </div>
         <div class="d-flex flex-wrap align-items-center">
            <div class="d-flex px-2 item-link">
               <span><svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M10 19C14.9706 19 19 14.9706 19 10C19 5.02944 14.9706 1 10 1C5.02944 1 1 5.02944 1 10C1 14.9706 5.02944 19 10 19Z" stroke="#DCE9FF" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                  <path d="M10 16C10.5523 16 11 15.5523 11 15C11 14.4477 10.5523 14 10 14C9.44772 14 9 14.4477 9 15C9 15.5523 9.44772 16 10 16Z" fill="#DCE9FF"></path>
                  <path d="M10 12V11.25C10.5933 11.25 11.1734 11.096 11.6667 10.8076C12.1601 10.5192 12.5446 10.1092 12.7716 9.62954C12.9987 9.14989 13.0581 8.62209 12.9424 8.11289C12.8266 7.60369 12.5409 7.13596 12.1213 6.76885C11.7018 6.40173 11.1672 6.15173 10.5853 6.05044C10.0033 5.94915 9.40013 6.00114 8.85195 6.19982C8.30377 6.3985 7.83524 6.73495 7.50559 7.16663C7.17595 7.59831 7 8.10582 7 8.625" stroke="#DCE9FF" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                  </svg>
                  </span>
               <a routerLink="/faqs">Câu hỏi thường gặp</a>
            </div>
            <div class="d-flex px-2 item-link ">
               <span><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="none" viewBox="0 0 20 20"><path fill="currentColor" fill-rule="evenodd" d="M2.274 13.622A2.727 2.727 0 0 1 0 10.933V9.271a2.727 2.727 0 0 1 2.727-2.727h.182a7.115 7.115 0 0 1 14.183 0h.181A2.727 2.727 0 0 1 20 9.271v1.662a2.727 2.727 0 0 1-2.727 2.727h-.805a.604.604 0 0 1-.604-.604V7.114a5.864 5.864 0 0 0-11.728 0v5.942c0 .333-.27.604-.604.604a3.704 3.704 0 0 0 3.682 3.313h.083a2.207 2.207 0 0 1 2.117-1.582h1.172a2.207 2.207 0 1 1 0 4.414H9.414a2.208 2.208 0 0 1-2.117-1.582h-.083a4.953 4.953 0 0 1-4.94-4.601zm9.269 3.976a.957.957 0 0 0-.957-.957H9.414a.957.957 0 1 0 0 1.914h1.172a.957.957 0 0 0 .957-.957zM2.886 7.794h-.159A1.477 1.477 0 0 0 1.25 9.271v1.662a1.477 1.477 0 0 0 1.477 1.477h.16V7.794zm14.228 0v4.616h.159a1.477 1.477 0 0 0 1.477-1.477V9.271a1.477 1.477 0 0 0-1.477-1.477h-.159z" clip-rule="evenodd"></path></svg></span>
               <a routerLink="/ho-tro">Chính sách hỗ trợ</a>
            </div>
            <div class="d-flex px-2 item-link">
               <span class="translate-y-[-1px]"><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="none" viewBox="0 0 20 20"><path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.2" d="M13.333 5h2.5a.833.833 0 01.833.833V15a1.667 1.667 0 01-3.333 0V4.166a.833.833 0 00-.833-.833H4.166a.833.833 0 00-.833.833v10a2.5 2.5 0 002.5 2.5H15M6.667 6.667H10M6.667 10H10M6.667 13.333H10"></path></svg></span>
               <a routerLink="/tin-tuc">Tin tức</a>
            </div>
         </div>
      </div>
      <div class="d-flex flex-wrap align-items-center">
         <div class="col-md-3 col-5">
            <a routerLink="/" class="logo" *ngIf="settings">
               <img  *ngIf="settings.logo" src="{{ settings.logo }}" alt="" class="img-fluid">
            </a>
         </div>
         <div class="col-md-6 col-12 d-none d-md-block">
            <div class="box-search d-flex">
               <input type="text" name="keyword" id="keyword" class="form-control" placeholder="Nhập tên sản phẩm muốn tìm ..." [(ngModel)]="searchKeyword" (keyup.enter)="searchProducts()">
               <button type="button" class="btn btn-search" (click)="searchProducts()">
                  <span class="object-cover text-white"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="17" fill="none" viewBox="0 0 16 17"><path fill="currentColor" fill-rule="evenodd" d="M1.778 7.943A5.339 5.339 0 0 1 7.11 2.61a5.339 5.339 0 0 1 5.333 5.333 5.339 5.339 0 0 1-5.333 5.333 5.339 5.339 0 0 1-5.333-5.333zm13.962 7.372l-3.018-3.019a7.068 7.068 0 0 0 1.5-4.353c0-3.92-3.19-7.111-7.11-7.111C3.19.832 0 4.022 0 7.943s3.19 7.111 7.111 7.111c1.641 0 3.15-.564 4.353-1.5l3.019 3.018a.886.886 0 0 0 1.257 0 .888.888 0 0 0 0-1.257z" clip-rule="evenodd"></path></svg></span>
               </button>
            </div>
         </div>
         <div class="col-md-3 col-7 d-flex align-items-center gr_account_cart justify-content-md-start justify-content-end">
            <a routerLink="/tra-cuu-don-hang" class="item d-md-flex align-items-center me-3 d-none">
               <span><svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="none" viewBox="0 0 32 32"><path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M18.667 4v5.333A1.333 1.333 0 0 0 20 10.667h5.334"></path><path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M22.667 28H9.334a2.667 2.667 0 01-2.667-2.667V6.667A2.667 2.667 0 019.334 4h9.333l6.667 6.667v14.666A2.667 2.667 0 0122.667 28zM12 9.332h1.333M12 17.332h8M17.333 22.668H20"></path></svg></span>
               Tra cứu <br >đơn hàng
            </a>
            <div class="account d-flex align-items-center me-3">
               <span>
                  <svg width="17" height="21" viewBox="0 0 17 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                     <circle cx="8.57894" cy="5.77803" r="4.77803" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></circle>
                     <path fill-rule="evenodd" clip-rule="evenodd" d="M1.00002 17.2014C0.998732 16.8655 1.07385 16.5337 1.2197 16.2311C1.67736 15.3158 2.96798 14.8307 4.03892 14.611C4.81128 14.4462 5.59431 14.336 6.38217 14.2815C7.84084 14.1533 9.30793 14.1533 10.7666 14.2815C11.5544 14.3367 12.3374 14.4468 13.1099 14.611C14.1808 14.8307 15.4714 15.27 15.9291 16.2311C16.2224 16.8479 16.2224 17.564 15.9291 18.1808C15.4714 19.1419 14.1808 19.5812 13.1099 19.7918C12.3384 19.9634 11.5551 20.0766 10.7666 20.1304C9.57937 20.2311 8.38659 20.2494 7.19681 20.1854C6.92221 20.1854 6.65677 20.1854 6.38217 20.1304C5.59663 20.0773 4.81632 19.9641 4.04807 19.7918C2.96798 19.5812 1.68652 19.1419 1.2197 18.1808C1.0746 17.8747 0.999552 17.5401 1.00002 17.2014Z" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                  </svg>                                       
               </span>
               <ng-container *ngIf="token; else loggedOut">
                  <a routerLink="/profile">Xin chào<br /><b>{{ user?.surName }}</b></a>
                  <ul class="list-mem">
                     <li><a routerLink="/profile">Thông tin tài khoản</a></li>
                     <li><a routerLink="/lich-su-mua-hang">Lịch sử mua hàng</a></li>
                     <li><a routerLink="/change-pass">Đổi mật khẩu</a></li>
                     <li><a routerLink="/logout">Thoát</a></li>
                  </ul>
              </ng-container>
              <ng-template #loggedOut>
                  <a routerLink="/login">Đăng nhập <br /><b>Tài khoản</b></a>
              </ng-template>
            </div>
            <div class="position-relative cart">
               <a routerLink="/cart">
                  <span class="mr-1"><svg xmlns="http://www.w3.org/2000/svg" width="23" height="22" fill="none" viewBox="0 0 23 22"><g stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" clip-path="url(#clip0_3996_21505)"><path fill="currentColor" stroke-width="2" d="M9.122 20.165a.917.917 0 100-1.833.917.917 0 000 1.833zM19.204 20.165a.917.917 0 100-1.833.917.917 0 000 1.833z"></path><path stroke-width="1.5" d="M1.787.918h3.667L7.91 13.192a1.833 1.833 0 0 0 1.834 1.476h8.91a1.833 1.833 0 0 0 1.833-1.476l1.467-7.69H6.37"></path></g><defs><clipPath id="clip0_3996_21505"><path fill="#fff" d="M0 0h22v22H0z" transform="translate(.871)"></path></clipPath></defs></svg></span>
                  <span id="cartNum">{{ cartItemCount }}</span>
               </a>
            </div>
            <div class="ic-menu-bar d-md-none d-block" (click)="toggleNav()">
               <svg xmlns="http://www.w3.org/2000/svg" width="30" height="16" viewBox="0 0 30 16">
                  <rect x="10" width="20" height="2" fill="#fff"></rect>
                  <rect x="5" y="7" width="25" height="2" fill="#fff"></rect>
                  <rect x="10" y="14" width="20" height="2" fill="#fff"></rect>
               </svg>
            </div>
         </div>
      </div>
   </div>
   <app-nav [openNav]="openNav" (closeNav)="openNav = false"></app-nav>
</header>