<main class="bg-gray-100 py-3">
    <div class="container">
        <h1 class="title_page">{{title}}</h1>
        <div class="grid_container">
            <div class="columns-md-2 columns-xs-4 item_brand" *ngFor="let item of brands">
                <a [routerLink]="[item.slug]">
                    <img *ngIf="item.photo; else usePhoto" [src]="item.photo" alt="{{item.name}}" class="img-fluid">
                    <!-- Nếu không có thumbnail thì hiển thị ảnh photo -->
                    <ng-template #usePhoto>
                        <img src="assets/images/no-image.jpg" alt="{{item.name}}" class="img-fluid">
                    </ng-template>
                </a>
            </div>
        </div>
        <ngb-pagination *ngIf="totalPages/90>1"
            [collectionSize]="totalPages"
            [(page)]="currentPage"
            [pageSize]="90"
            [rotate]="true"
            [boundaryLinks]="true"
            (pageChange)="loadPage($event)"
            [maxSize]="5"
        ></ngb-pagination>
    </div>
</main>