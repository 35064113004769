import { Routes } from '@angular/router';
import { HomeComponent } from './pages/home/home.component';
import { LoginComponent } from './pages/auth/login/login.component';
import { RegisterComponent } from './pages/auth/register/register.component';
import { ForgotPasswordComponent } from './pages/auth/forgot-password/forgot-password.component';
import { ProductComponent } from './pages/product/product.component';
import { CategoriesComponent } from './pages/product/categories/categories.component';
import { DetailComponent } from './pages/product/detail/detail.component';
import { AboutComponent } from './pages/about/about.component';
import { AboutDetailComponent } from './pages/about/about-detail/about-detail.component';
import { NewsComponent } from './pages/news/news.component';
import { CategoriesNewsComponent } from './pages/news/categories-news/categories-news.component';
import { DetailNewsComponent } from './pages/news/detail-news/detail-news.component';
import { ListNewsComponent } from './pages/news/list-news/list-news.component';
import { NotFoundComponent } from './pages/not-found/not-found.component';
import { LogoutComponent } from './pages/auth/logout/logout.component';
import { ListProductComponent } from './pages/product/list-product/list-product.component';
import { CartComponent } from './pages/cart/cart.component';
import { CheckoutComponent } from './pages/checkout/checkout.component';
import { OrderConfirmComponent } from './pages/order-confirm/order-confirm.component';
import { OrderComponent } from './pages/order/order.component';
import path from 'node:path';
import { ArticleComponent } from './pages/article/article.component';
import { ListArticleComponent } from './pages/article/list-article/list-article.component';
import { DetailArticleComponent } from './pages/article/detail-article/detail-article.component';
import { ContactComponent } from './pages/contact/contact.component';
import { ProfileComponent } from './pages/auth/profile/profile.component';
import { ChangePassComponent } from './pages/auth/change-pass/change-pass.component';
import { HistoryOrderComponent } from './pages/auth/history-order/history-order.component';
import { SearchComponent } from './pages/search/search.component';
import { BrandComponent } from './pages/brand/brand.component';
import { ListBrandComponent } from './pages/brand/list-brand/list-brand.component';
import { DetailBrandComponent } from './pages/brand/detail-brand/detail-brand.component';
import { ContentArticleComponent } from './pages/content-article/content-article.component';
import { BrandCategorieComponent } from './pages/product/brand-categorie/brand-categorie.component';
import { brandUrlMatcher, categoryUrlMatcher, newsCategoryUrlMatcher, newsDetailUrlMatcher } from './custom-url-matcher';

export const routes: Routes = [
    {
        path: '',
        component:HomeComponent,
    },
    {
        path: 'login',
        component:LoginComponent,
    },
    {
        path: 'logout',
        component:LogoutComponent,
    },
    {
        path: 'register',
        component:RegisterComponent,
    },
    {
        path: 'forgot-password',
        component:ForgotPasswordComponent,
    },{
        path: 'profile',
        component:ProfileComponent,
    },{
        path: 'lich-su-mua-hang',
        component:HistoryOrderComponent,
    },{
        path: 'change-pass',
        component:ChangePassComponent,
    },{
        path: 'cart',
        component:CartComponent,
    },{
        path: 'checkout',
        component: CheckoutComponent,
    },{
        path: 'order-confirmation/:orderID',
        component: OrderConfirmComponent,
    },{
        path: 'tra-cuu-don-hang',
        component:OrderComponent,
    },{
        path: 'lien-he',
        component:ContactComponent,
    },{
        path: 'chinh-sach-bao-mat',
        component:ContentArticleComponent,
        data: { type: 'baomat', title:'Chính sách bảo mật',com:'chinh-sach-bao-mat' },
    },{
        path: 'dieu-khoan',
        component:ContentArticleComponent,
        data: { type: 'policy', title:'Điều khoản và điều kiện',com:'dieu-khoan' },
    },{
        path: 'thuong-hieu',
        component: BrandComponent,
        children:[
            {
                path: '',  // Khi vào giải pháp, hiển thị toàn bộ bài viết
                component: ListBrandComponent, // hoặc một component khác nếu cần
            },{
            path: ':slug', // URL cho phần "danh mục"
            component: DetailBrandComponent,
            }
        ]
    },{
        path: 'gioi-thieu',
        component:ArticleComponent,
        data: { type: 'about', title:'Giới thiệu',com:'gioi-thieu' },
        children:[
            {
                path: '',  // Khi vào giải pháp, hiển thị toàn bộ bài viết
                component: ListArticleComponent, // hoặc một component khác nếu cần
            },{
            path: ':slug', // URL cho phần "danh mục"
            component: DetailArticleComponent,
            }
        ]
    },{
        path: 'tuyen-dung',
        component:ArticleComponent,
        data: { type: 'tuyendung', title:'Tuyển dụng',com:'tuyen-dung' },
        children:[
            {
                path: '',  // Khi vào giải pháp, hiển thị toàn bộ bài viết
                component: ListArticleComponent, // hoặc một component khác nếu cần
            },{
            path: ':slug', // URL cho phần "danh mục"
            component: DetailArticleComponent,
            }
        ]
    },{
        path: 'faqs',
        component:ArticleComponent,
        data: { type: 'faqs', title:'Câu hỏi thường gặp',com:'faqs' },
        children:[
            {
                path: '',  // Khi vào giải pháp, hiển thị toàn bộ bài viết
                component: ListArticleComponent, // hoặc một component khác nếu cần
            },{
            path: ':slug', // URL cho phần "danh mục"
            component: DetailArticleComponent,
            }
        ]
    },{
        path: 'ho-tro',
        component:ArticleComponent,
        data: { type: 'support', title:'Chính sách hỗ trợ',com:'ho-tro' },
        children:[
            {
                path: '',  // Khi vào giải pháp, hiển thị toàn bộ bài viết
                component: ListArticleComponent, // hoặc một component khác nếu cần
            },{
            path: ':slug', // URL cho phần "danh mục"
            component: DetailArticleComponent,
            }
        ]
    },
    // {
    //     path: 'gioi-thieu',
    //     component:AboutComponent,
    //     data: { type: 'about' }
    // },{
    //     path: 'gioi-thieu/:id',
    //     component:AboutDetailComponent,
    //     data: { type: 'about' }
    // },
    {
        path: 'giai-phap', // URL cho phần "giải pháp"
        component: NewsComponent,
        data: { type: 'giaiphap', title:'Giải pháp',com:'giai-phap' },
        children: [
            {
                path: '',  // Khi vào giải pháp, hiển thị toàn bộ bài viết
                component: ListNewsComponent, // hoặc một component khác nếu cần
            },{
            path: ':categorySlug', // URL cho phần "danh mục"
            component: CategoriesNewsComponent,
            },
            {
                path: ':categorySlug/:articleSlug', // URL cho phần "bài viết"
                component: DetailNewsComponent,
                data: { type: 'giaiphap', title:'Giải pháp',com:'giai-phap' },
            }
        ]
    },{
        path: 'tin-tuc', // URL cho phần "giải pháp"
        component: NewsComponent,
        data: { type: 'news', title:'Tin tức',com:'tin-tuc' },
        children: [
            {
                path: '',  // Khi vào giải pháp, hiển thị toàn bộ bài viết
                component: ListNewsComponent, // hoặc một component khác nếu cần
            },{
            path: ':categorySlug', // URL cho phần "danh mục"
            component: CategoriesNewsComponent,
            },
            {
                path: ':categorySlug/:articleSlug', // URL cho phần "bài viết"
                component: DetailNewsComponent,
                data: { type: 'news', title:'Tin tức',com:'tin-tuc' },
            }
        ]
    },{
        path: 'dich-vu', // URL cho phần "giải pháp"
        component: NewsComponent,
        data: { type: 'service', title:'Dịch vụ',com:'dich-vu' },
        children: [
            {
                path: '',  // Khi vào giải pháp, hiển thị toàn bộ bài viết
                component: ListNewsComponent, // hoặc một component khác nếu cần
            },{
            path: ':categorySlug', // URL cho phần "danh mục"
            component: CategoriesNewsComponent,
            },
            {
                path: ':categorySlug/:articleSlug', // URL cho phần "bài viết"
                component: DetailNewsComponent,
                data: { type: 'service', title:'Dịch vụ',com:'dich-vu' },
            }
        ]
    },{
        path: 'tim-kiem/:keyword',
        component:SearchComponent,
        data: { type: 'about' }
    },
    // {
    //     path: ':slug-c:id',
    //     component: CategoriesComponent,
    // },
    {
        path: 'san-pham/:slug-tjk:id', // Route cho sản phẩm với slug và id
        component: DetailComponent, // Component sẽ hiển thị chi tiết sản phẩm
    },
    {
        path: 'san-pham.html', // Route cho trang danh sách sản phẩm
        component: ListProductComponent, // Component sẽ hiển thị danh sách sản phẩm
    },{
        path: 'san-pham', // URL cho phần "giải pháp"
        component: ProductComponent,
        children: [
            {
                path: '',  // Khi vào giải pháp, hiển thị toàn bộ bài viết
                component: ListProductComponent, // hoặc một component khác nếu cần
            },{
            path: ':categorySlug', // URL cho phần "danh mục"
            component: CategoriesComponent,
            },
            {
                path: ':categorySlug/:productSlug', // URL cho phần "bài viết"
                component: DetailComponent,
            }
        ]
    },{ path: '404', component: NotFoundComponent },
    {
    matcher: newsDetailUrlMatcher,
    component: DetailNewsComponent
    },
    {
    matcher: brandUrlMatcher,
    component: BrandCategorieComponent
    },
    {
    matcher: categoryUrlMatcher,
    component: CategoriesComponent
    },
    {
    matcher: newsCategoryUrlMatcher,
    component: CategoriesNewsComponent
    },
    { path: '**', redirectTo: '/404' }
];
