<main class="bg-gray-100 py-5">
   @defer (on timer(2s); when PRODUCT_SELECTED) {
   <section  *ngIf="!isNotFound; else notFoundTemplate">
    <!-- breadcrumb area start -->
    <section class="breadcrumb__area breadcrumb__style-2 include-bg mb-3">
       <div class="container" *ngIf="PRODUCT_SELECTED">
          <div class="row">
             <div class="col-xxl-12">
                <div class="breadcrumb__content p-relative z-index-1">
                   <div class="breadcrumb__list has-icon">
                      <span class="breadcrumb-icon">
                         <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M1.42393 16H15.5759C15.6884 16 15.7962 15.9584 15.8758 15.8844C15.9553 15.8104 16 15.71 16 15.6054V6.29143C16 6.22989 15.9846 6.1692 15.9549 6.11422C15.9252 6.05923 15.8821 6.01147 15.829 5.97475L8.75305 1.07803C8.67992 1.02736 8.59118 1 8.5 1C8.40882 1 8.32008 1.02736 8.24695 1.07803L1.17098 5.97587C1.11791 6.01259 1.0748 6.06035 1.04511 6.11534C1.01543 6.17033 0.999976 6.23101 1 6.29255V15.6063C1.00027 15.7108 1.04504 15.8109 1.12451 15.8847C1.20398 15.9585 1.31165 16 1.42393 16ZM10.1464 15.2107H6.85241V10.6202H10.1464V15.2107ZM1.84866 6.48977L8.4999 1.88561L15.1517 6.48977V15.2107H10.9946V10.2256C10.9946 10.1209 10.95 10.0206 10.8704 9.94654C10.7909 9.87254 10.683 9.83096 10.5705 9.83096H6.42848C6.316 9.83096 6.20812 9.87254 6.12858 9.94654C6.04904 10.0206 6.00435 10.1209 6.00435 10.2256V15.2107H1.84806L1.84866 6.48977Z" fill="#55585B" stroke="#55585B" stroke-width="0.5"/>
                         </svg>
                      </span>
                      <span><a routerLink="/">Home</a></span>
                      <span *ngIf="PRODUCT_SELECTED.categorie_first_id>0"><a routerLink="/{{ PRODUCT_SELECTED?.categorie_first.slug }}-c{{PRODUCT_SELECTED?.categorie_first.id}}">{{ PRODUCT_SELECTED?.categorie_first.name }}</a></span>
                      <span *ngIf="PRODUCT_SELECTED?.categorie_second_id>0"><a routerLink="/{{ PRODUCT_SELECTED?.categorie_second.slug }}-c{{PRODUCT_SELECTED?.categorie_second.id}}">{{ PRODUCT_SELECTED?.categorie_second.name }}</a></span>
                      <span>{{ PRODUCT_SELECTED.name }}</span>
                   </div>
                </div>
             </div>
          </div>
       </div>
    </section>
    <!-- breadcrumb area end -->

    <!-- product details area start -->
    <section class="tp-product-details-area">
      <div class="container">
         <div class="bg-white p-3 shadow-3 mb-5" >
            <div class="row mb-5">
               <div class="col-12 col-sm-12 col-lg-4 col-md-4">
                  <app-slider [product]="PRODUCT_SELECTED"></app-slider>
               </div>
               <div class="col-12 col-sm-12 col-lg-5 col-md-5 product-detail">
                  <h1 class="name-product mb-3 pb-2">{{ PRODUCT_SELECTED.name }}</h1>
                  <div class="tp-product-details-price-wrapper mb-4 d-flex flex-wrap align-items-center justify-content-between">
                     <div>
                        <!-- Giá cũ nếu có giá -->
                        <span class="tp-product-details-price old-price" *ngIf="price > 0">
                            {{ price | number:'1.0-0' }} đ
                        </span>
                    
                        <!-- Giá mới nếu có giá sale -->
                        <span class="tp-product-details-price new-price" *ngIf="priceSale > 0; else contactTemplate">
                            {{ priceSale | number:'1.0-0' }} đ
                        </span>
                    
                        <!-- Template 'Liên hệ' khi không có giá sale -->
                        <ng-template #contactTemplate>
                            <span class="tp-product-details-price new-price">Liên hệ</span>
                        </ng-template>
                    </div>
                     <!-- <div class="tp-product-details-rating">
                        <span><i class="fas fa-star"></i></span>
                        <span><i class="fas fa-star"></i></span>
                        <span><i class="fas fa-star"></i></span>
                        <span><i class="fas fa-star"></i></span>
                        <span><i class="fas fa-star"></i></span>
                     </div> -->
                  </div>
                  <div class="row product_meta mb-3">
                     <!-- <div class="col-6 mb-2" *ngIf="PRODUCT_SELECTED.sku">
                        <strong>SKU: </strong>
                        <span>{{ sku }}</span>
                     </div> -->
                     <div class="col-6 mb-2" *ngIf="PRODUCT_SELECTED.model">
                        <strong>Model: </strong>
                        <span>{{ PRODUCT_SELECTED.model }}</span>
                     </div>
                     <div class="col-6 mb-2" *ngIf="PRODUCT_SELECTED.stock">
                        <strong>Tình trạng: </strong>
                        <span *ngIf="PRODUCT_SELECTED.stock>0; else useOutstock">Còn hàng</span>
                        <ng-template #useOutstock>
                           <span>Hết hàng</span>
                        </ng-template>
                     </div>
                     
                     <div class="col-6 mb-2" *ngIf="PRODUCT_SELECTED.partnumber">
                        <strong>Partnumber: </strong>
                        <span>{{ PRODUCT_SELECTED.partnumber }}</span>
                     </div>
                     <div class="col-6 mb-2" *ngIf="PRODUCT_SELECTED.brand">
                        <strong>Nhà sản xuất: </strong>
                        <span>{{ PRODUCT_SELECTED.brand.name }}</span>
                     </div>
                     <div class="col-6 mb-2" *ngIf="PRODUCT_SELECTED.origin">
                        <strong>Xuất xứ: </strong>
                        <span>{{ PRODUCT_SELECTED.origin.name }}</span>
                     </div>
                     <div class="col-6 mb-2" *ngIf="PRODUCT_SELECTED.guarantee">
                        <strong>Bảo hành: </strong>
                        <span>{{ PRODUCT_SELECTED.guarantee.name }}</span>
                     </div>
                  </div>
                  <div class="info-product mb-3" *ngIf="PRODUCT_SELECTED?.mota && PRODUCT_SELECTED?.mota != null" [innerHTML]="PRODUCT_SELECTED.mota"></div>

                  <!-- variations -->
                  <div class="tp-product-details-variation" *ngIf="PRODUCT_SELECTED.options.length>0">
                     <!-- Lặp qua từng attribute trong PRODUCT_SELECTED.attributes -->
                     <div class="tp-product-details-variation-item">
                        <h4 class="tp-product-details-variation-title mt-3" *ngIf="PRODUCT_SELECTED.title_option">{{ PRODUCT_SELECTED.title_option }} :</h4>
                        <div class="tp-product-details-variation-list">
                           <ng-container *ngFor="let item of PRODUCT_SELECTED.options">
                              <a [routerLink]="['/san-pham', item.option_product.slug + '-tjk' + item.option_product.id]" class="btn tp-size-variation-btn me-2" >{{ item.title }}</a>
                           </ng-container>
                        </div>
                        <!-- @for(attribute of attributes; track attribute.id){
                           <h4 class="tp-product-details-variation-title mt-3">{{ attribute.name }} :</h4>
                           <div class="tp-product-details-variation-list">
                              @for(propertie of attribute.properties; track propertie.id){
                                 @if(propertie.selected==true && propertie.code){
                                    <button type="button" class="color tp-color-variation-btn mx-1" [ngClass]="{'active': isPropertySelected(attribute.id, propertie.id), 'disabled': !propertie.available}"
                                    (click)="selectedVariation(attribute.id, propertie)">
                                       <span [attr.data-bg-color]="propertie.code" [style.background]="propertie.code"></span>
                                       <span class="tp-color-variation-tootltip">{{ propertie.name }}</span>
                                    </button>
                                 }
                                 @if(propertie.selected==true && !propertie.code){
                                    <button type="button" class=" tp-size-variation-btn" [ngClass]="{'active': isPropertySelected(attribute.id, propertie.id), 'disabled': !propertie.available}"
                                    (click)="selectedVariation(attribute.id, propertie)">
                                       <span>{{propertie.name  }}</span>
                                    </button>
                                 }
                              }
                           </div>
                        } -->
                     </div>
                  </div>
                   
                   <!-- Hiển thị thông tin variation đã chọn -->
                   <!-- <div *ngIf="variation_selected">
                     <h3>Thông tin sản phẩm:</h3>
                     <p>Giá: {{ variation_selected.price | number:'1.0-0' }} đ</p>
                     <p>Giá khuyến mãi: {{ variation_selected.priceSale | number:'1.0-0' }} đ</p>
                     <img [src]="variation_selected.image" alt="Hình ảnh sản phẩm" class="img-fluid">
                   </div> -->

                   
                  

                  <!-- actions -->
                  <div class="tp-product-details-action-wrapper">
                     <h3 class="tp-product-details-action-title">Số lượng</h3>
                     <div class="tp-product-details-action-item-wrapper d-flex flex-wrap align-items-center  mb-3">
                        <div class="tp-product-details-quantity">
                           <div class="tp-product-quantity mb-3 me-3">
                              <span class="tp-cart-minus" (click)="decrementQuantity()">
                                 <svg width="11" height="2" viewBox="0 0 11 2" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M1 1H10" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                 </svg>                                                            
                              </span>
                              <input class="tp-cart-input" type="text" value="1" [(ngModel)]="quantity">
                              <span class="tp-cart-plus" (click)="incrementQuantity()">
                                 <svg width="11" height="12" viewBox="0 0 11 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M1 6H10" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                    <path d="M5.5 10.5V1.5" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                 </svg>
                              </span>
                           </div>
                        </div>
                        <div class="tp-product-details-add-to-cart mb-3 me-2">
                           <button class="tp-product-details-add-to-cart-btn w-100" (click)="addCart()">Thêm vào giỏ hàng</button>
                        </div>
                        <div class="tp-product-details-buy-now mb-3">
                           <button class="tp-product-details-buy-now-btn w-100 btn" (click)="buynow()">Mua ngay</button>
                        </div>
                     </div>
                     
                  </div>
                  <div class="tp-product-details-social">
                     <span>Chia sẻ: </span>
                     
                     <a href="https://www.facebook.com/sharer.php?u={{ URL_FRONTEND }}/san-pham/{{ PRODUCT_SELECTED.slug }}" data-label="Facebook" onclick="window.open(this.href,this.title,'width=500,height=500,top=300px,left=300px');  return false;" rel="noopener noreferrer nofollow" target="_blank" class="icon button circle is-outline facebook tooltipstered" data-mdb-toggle="tooltip" title="" data-mdb-original-title="Share on Facebook">
                        <i class="fab fa-facebook-f"></i>
                     </a>
                     <a href="https://twitter.com/share?url={{ URL_FRONTEND }}/san-pham/{{ PRODUCT_SELECTED.slug }}" onclick="window.open(this.href,this.title,'width=500,height=500,top=300px,left=300px');  return false;" rel="noopener noreferrer nofollow" target="_blank" class="icon button circle is-outline twitter tooltipstered" data-mdb-toggle="tooltip" title="" data-mdb-original-title="Share on Twitter">
                        <i class="fab fa-twitter"></i>
                     </a>
                     <a href="mailto:enteryour@addresshere.com?subject={{ PRODUCT_SELECTED.name }}&amp;body=Check%20this%20out:%20{{ URL_FRONTEND }}/san-pham/{{ PRODUCT_SELECTED.slug }}" rel="nofollow" class="icon button circle is-outline email tooltipstered" data-mdb-toggle="tooltip" title="" data-mdb-original-title="Email to a Friend">
                        <i class="far fa-envelope"></i>
                     </a>
                     <a href="https://pinterest.com/pin/create/button/?url={{ URL_FRONTEND }}/san-pham/{{ PRODUCT_SELECTED.slug }}&amp;media={{ PRODUCT_SELECTED.photo }}&amp;description={{ PRODUCT_SELECTED.name }}" onclick="window.open(this.href,this.title,'width=500,height=500,top=300px,left=300px');  return false;" rel="noopener noreferrer nofollow" target="_blank" class="icon button circle is-outline pinterest tooltipstered" data-mdb-toggle="tooltip" title="" data-mdb-original-title="Pin on Pinterest">
                        <i class="fab fa-pinterest-p"></i>
                     </a>
                     <a href="https://www.linkedin.com/shareArticle?mini=true&amp;url={{ URL_FRONTEND }}/san-pham/{{ PRODUCT_SELECTED.slug }}&amp;title={{ PRODUCT_SELECTED.name }}" onclick="window.open(this.href,this.title,'width=500,height=500,top=300px,left=300px');  return false;" rel="noopener noreferrer nofollow" target="_blank" class="icon button circle is-outline linkedin tooltipstered" data-mdb-toggle="tooltip" title="" data-mdb-original-title="Share on LinkedIn">
                        <i class="fab fa-linkedin-in"></i>
                     </a>
                  </div>
               </div>
               <div class="col-xs-12 col-sm-12 col-lg-3 col-md-3 single_sidebar">
                  <div class=" sticky-top">
                     <div class="item d-flex align-items-center">
                        <img alt="Chính hãng 100%" class="img-fluid lazyload" width="60px" height="60px" src="assets/images/vanchuyen/quality.png.webp" style="display: inline;">
                        <div class="info ps-3">
                           <div class="name">Chính hãng 100%</div>
                           <p>Sản phẩm chính hãng, nguồn gốc rõ ràng</p>
                        </div>
                     </div>
                     <div class="item d-flex align-items-center">
                        <img alt="Giá tốt nhất" class="img-fluid lazyload" width="60px" height="60px" src="assets/images/vanchuyen/best-price.png.webp" style="display: inline;">
                        <div class="info ps-3">
                           <div class="name">Giá tốt nhất</div>
                           <p>Yên tâm mua sắm, giải toả lo âu</p>
                        </div>
                     </div>
                     <div class="item d-flex align-items-center">
                        <img alt="Giao hàng nhanh chóng" class="img-fluid lazyload" width="60px" height="60px" src="assets/images/vanchuyen/fast-delivery.png.webp" style="display: inline;">
                        <div class="info ps-3">
                           <div class="name">Giao hàng nhanh chóng</div>
                           <p>Giao hàng siêu tốc trong 24h</p>
                        </div>
                     </div>
                     <div class="item d-flex align-items-center">
                        <img alt="Thanh toán linh hoạt" class="img-fluid lazyload" width="60px" height="60px" src="assets/images/vanchuyen/credit-cards.png.webp" style="display: inline;">
                        <div class="info">
                           <div class="name">Thanh toán linh hoạt</div>
                           <p>Chuyển khoản hoặc ship COD</p>
                        </div>
                     </div>
                  </div>
                  
               </div>
            </div>
         </div>
         <div class="bg-white shadow-3 p-3 mb-5" *ngIf="PRODUCT_SELECTED.accessories.length>0">
            <h2 class="title_page">Phụ kiện kèm theo</h2>
            <div class="grid_container1">
               <div class="columns-md-2 columns-xs-6" *ngFor="let product of PRODUCT_SELECTED.accessories" >
                  <div class="item_product">
                     <div class="images" *ngIf="product.image">
                        <a routerLink="/san-pham/{{ product.slug }}-tjk{{ product.id }}">
                           <img *ngIf="product.image.thumbnail; else usePhoto" [src]="product.image.thumbnail" alt="{{product.name}}" class="img-fluid">
                           <!-- Nếu không có thumbnail thì hiển thị ảnh photo -->
                           <ng-template #usePhoto>
                           <img src="assets/images/no-image.jpg" alt="{{product.name}}" class="img-fluid">
                           </ng-template>
                        </a>
                     </div>
                     <div class="info p-4 py-2">
                        <a routerLink="/san-pham/{{ product.slug }}-tjk{{ product.id }}">
                              <h3>{{ product.name }}</h3>
                           </a>
                     </div>
                  </div>
               </div>
            </div>
         </div>
         <div class="row">
            <div class="col-lg-9 col-md-9 col-12">
               <div class="bg-white shadow-3 p-3 tp-product-details-bottom">
                  <div class="tp-product-details-tab-nav tp-tab">
                     <ul class="nav nav-tabs mb-3 mytabs justify-content-start" id="ex1" role="tablist">
                        <li class="nav-item active" role="presentation" *ngIf="PRODUCT_SELECTED.documents">
                           <a class="nav-link active" id="ex1-tab-5" data-mdb-toggle="tab" href="#ex1-tabs-5" role="tab" aria-controls="ex1-tabs-5" aria-selected="true">Thông số kỹ thuật</a>
                        </li>
                        <li class="nav-item" role="presentation">
                           <a class="nav-link" id="ex1-tab-1" data-mdb-toggle="tab" href="#ex1-tabs-1" role="tab" aria-controls="ex1-tabs-1" aria-selected="true">Mô tả</a>
                        </li>
                        <li class="nav-item" role="presentation" *ngIf="PRODUCT_SELECTED.videos.length>0">
                           <a class="nav-link" id="ex1-tab-3" data-mdb-toggle="tab" href="#ex1-tabs-3" role="tab" aria-controls="ex1-tabs-3" aria-selected="true">Video</a>
                        </li>
                        <li class="nav-item" role="presentation" *ngIf="PRODUCT_SELECTED.documents.length>0">
                           <a class="nav-link" id="ex1-tab-4" data-mdb-toggle="tab" href="#ex1-tabs-4" role="tab" aria-controls="ex1-tabs-4" aria-selected="true">Tài liệu</a>
                        </li>
                        
                        <li class="nav-item" role="presentation">
                           <a class="nav-link" id="ex1-tab-2" data-mdb-toggle="tab" href="#ex1-tabs-2" role="tab" aria-controls="ex1-tabs-2" aria-selected="false">Hãng sản xuất</a>
                        </li>
                     </ul>
                     
                     <div class="tab-content" id="ex1-content">
                        <div class="tab-pane fade active show" id="ex1-tabs-5" role="tabpanel" aria-labelledby="ex1-tab-5">
                           <div class="tp-product-details-desc-wrapper pt-3">
                              <div class="row justify-content-center" *ngIf="PRODUCT_SELECTED.specification">
                                 <div [innerHtml]="PRODUCT_SELECTED.specification">
                                    
                                 </div>
                              </div>
                           </div>
                        </div>
                        <div class="tab-pane fade" id="ex1-tabs-1" role="tabpanel" aria-labelledby="ex1-tab-1">
                           <div class="tp-product-details-desc-wrapper pt-3">
                              <div class="row justify-content-center" *ngIf="PRODUCT_SELECTED.content">
                                 <div [innerHtml]="PRODUCT_SELECTED.content">
                                    
                                 </div>
                              </div>
                           </div>
                        </div>
                        <div class="tab-pane fade" id="ex1-tabs-3" role="tabpanel" aria-labelledby="ex1-tab-3" *ngIf="PRODUCT_SELECTED.videos.length>0">
                           <div class="tp-product-details-desc-wrapper">
                              
                              <div class="mb-5 group-video" *ngFor="let video of PRODUCT_SELECTED.videos; let i = index">
                                 <h2>{{ video.title }}</h2>
                                 <div class="videoWrapper">
                                    <youtube-player
                                    [videoId]="extractVideoId(video.video)"
                                    suggestedQuality="small"
                                    [startSeconds]="startSeconds"
                                    [endSeconds]="endSeconds"
                                    [height]="height"
                                    [width]="width"
                                    >
                                    </youtube-player>
                                   
                                 </div>
                               </div>
                           </div>
                        </div>
                        <div class="tab-pane fade" id="ex1-tabs-4" role="tabpanel" aria-labelledby="ex1-tab-4" *ngIf="PRODUCT_SELECTED.documents">
                           <div class="tp-product-details-desc-wrapper">
                              <h2>Tài liệu {{ PRODUCT_SELECTED.name }}</h2>
                              <ul class="documents">
                                 <li *ngFor="let item of PRODUCT_SELECTED.documents">Download <a href="{{ item.file }}" target="_blank">{{ item.title }}</a></li>
                              </ul>
                           </div>
                        </div>
                        
                        <div class="tab-pane fade" id="ex1-tabs-2" role="tabpanel" aria-labelledby="ex1-tab-2">
                           <div class="tp-product-details-desc-wrapper pt-5">
                              <div class="row justify-content-center" *ngIf="PRODUCT_SELECTED.brand">
                                 <div [innerHtml]="PRODUCT_SELECTED.brand.content">
                                    
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                     <div class="content_product" *ngIf="PRODUCT_SELECTED.accessorieBuys.length>0">
                        <div class="mt-5 title_page">Phụ kiện mua thêm</div>
                        <div class="d-flex flex-wrap">
                           <div class="item_accessorie col-md-2 col-6" *ngFor="let product of PRODUCT_SELECTED.accessorieBuys">
                              <a routerLink="/san-pham/{{ product.slug }}-tjk{{ product.id }}">
                                 <img *ngIf="product.image; else usePhoto" [src]="product.image.thumbnail" alt="{{product.name}}" class="img-fluid">
                                 <!-- Nếu không có thumbnail thì hiển thị ảnh photo -->
                                 <ng-template #usePhoto>
                                 <img src="assets/images/no-image.jpg" alt="{{product.name}}" class="img-fluid">
                                 </ng-template>
                                 <h3 class="mt-3">{{ product.name }}</h3>
                              </a>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
            <div class="col-lg-3 col-md-3 col-12">
               <div class="bg-white shadow-3 p-3 sticky-top">
                  <div class="content mb-3" *ngIf="PRODUCT_SELECTED.news.length>0">
                     <div class="title_page">Tin tức liên quan</div>
                     <div class="tp-shop-widget-product-item d-flex align-items-center mb-3 border-bottom pb-3" *ngFor="let news of PRODUCT_SELECTED.news">
                        <div class="tp-shop-widget-product-thumb col-3">
                           <a [routerLink]="['/', news.news_categories.slug, news.slug+ '-p' + news.id+'.html']">
                             <img *ngIf="news.image; else usePhoto" appLazyLoad="{{ news.image.thumbnail }}" src="assets/images/400x400.png" alt="{{news.name}}" class="img-fluid" loading="lazy" (error)="onImageError($event)">
                             <!-- Nếu không có thumbnail thì hiển thị ảnh photo -->
                             <ng-template #usePhoto>
                             <img src="assets/images/no-image.jpg" alt="{{news.name}}" class="img-fluid">
                             </ng-template>
                           </a>
                        </div>
                        <div class="tp-shop-widget-product-content col-9 ps-3">
                           <h4 class="tp-shop-widget-product-title">
                              <a [routerLink]="['/', news.news_categories.slug, news.slug+ '-p' + news.id+'.html']">
                                 {{ news.name }}
                              </a>
                           </h4>
                        </div>
                     </div>
                  </div>
                  <div class="content mb-3" *ngIf="PRODUCT_SELECTED.buytogethers.length>0">
                     <div class="title_page">Sản phẩm mua cùng</div>
                     <div class="tp-shop-widget-product-item d-flex align-items-center mb-3 border p-2" *ngFor="let product of PRODUCT_SELECTED.buytogethers">
                        <div class="tp-shop-widget-product-thumb col-3">
                           <a routerLink="/san-pham/{{ product.slug }}-tjk{{ product.id }}">
                             <img *ngIf="product.image; else usePhoto" appLazyLoad="{{ product.image.thumbnail }}" src="assets/images/400x400.png" alt="{{product.name}}" class="img-fluid" loading="lazy" (error)="onImageError($event)">
                             <!-- Nếu không có thumbnail thì hiển thị ảnh photo -->
                             <ng-template #usePhoto>
                             <img src="assets/images/no-image.jpg" alt="{{product.name}}" class="img-fluid">
                             </ng-template>
                           </a>
                        </div>
                        <div class="tp-shop-widget-product-content col-9 ps-3">
                           <div class="tp-shop-widget-product-rating-wrapper d-flex align-items-center">
                               <div class="tp-shop-widget-product-rating">
                                   <span>
                                      <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                         <path d="M6 0L7.854 3.756L12 4.362L9 7.284L9.708 11.412L6 9.462L2.292 11.412L3 7.284L0 4.362L4.146 3.756L6 0Z" fill="currentColor"></path>
                                      </svg>
                                   </span>
                                   <span>
                                      <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                         <path d="M6 0L7.854 3.756L12 4.362L9 7.284L9.708 11.412L6 9.462L2.292 11.412L3 7.284L0 4.362L4.146 3.756L6 0Z" fill="currentColor"></path>
                                      </svg>
                                   </span>
                                   <span>
                                      <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                         <path d="M6 0L7.854 3.756L12 4.362L9 7.284L9.708 11.412L6 9.462L2.292 11.412L3 7.284L0 4.362L4.146 3.756L6 0Z" fill="currentColor"></path>
                                      </svg>
                                   </span>
                                   <span>
                                      <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                         <path d="M6 0L7.854 3.756L12 4.362L9 7.284L9.708 11.412L6 9.462L2.292 11.412L3 7.284L0 4.362L4.146 3.756L6 0Z" fill="currentColor"></path>
                                      </svg>
                                   </span>
                                   <span>
                                      <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                         <path d="M6 0L7.854 3.756L12 4.362L9 7.284L9.708 11.412L6 9.462L2.292 11.412L3 7.284L0 4.362L4.146 3.756L6 0Z" fill="currentColor"></path>
                                      </svg>
                                   </span>
                               </div>
                           </div>
                           <h4 class="tp-shop-widget-product-title">
                              <a routerLink="/san-pham/{{ product.slug }}-tjk{{ product.id }}">
                                   {{ product.name }}
                                </a>
                           </h4>
                           <div class="tp-shop-widget-product-price-wrapper">
                              <span class="tp-shop-widget-product-price" *ngIf="product.priceSale > 0">
                                 {{ product.priceSale | number:'1.0-0' }} đ
                              </span>
                              <span class="tp-shop-widget-product-price" *ngIf="product.priceSale <= 0">
                                 Liên hệ
                              </span>
                           </div>
                        </div>
                     </div>
                  </div>

                  <div class="content mb-3" *ngIf="PRODUCT_SELECTED.replaces.length>0">
                     <div class="title_page">Sản phẩm thay thế</div>
                     <div class="tp-shop-widget-product-item d-flex align-items-center mb-3 border p-2" *ngFor="let product of PRODUCT_SELECTED.replaces">
                        <div class="tp-shop-widget-product-thumb col-3">
                           <a routerLink="/san-pham/{{ product.slug }}-tjk{{ product.id }}">
                             <img *ngIf="product.image; else usePhoto" appLazyLoad="{{ product.image.thumbnail }}" src="assets/images/400x400.png" alt="{{product.name}}" class="img-fluid" loading="lazy" (error)="onImageError($event)">
                             <!-- Nếu không có thumbnail thì hiển thị ảnh photo -->
                             <ng-template #usePhoto>
                             <img src="assets/images/no-image.jpg" alt="{{product.name}}" class="img-fluid">
                             </ng-template>
                           </a>
                        </div>
                        <div class="tp-shop-widget-product-content col-9 ps-3">
                           <div class="tp-shop-widget-product-rating-wrapper d-flex align-items-center">
                               <div class="tp-shop-widget-product-rating">
                                   <span>
                                      <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                         <path d="M6 0L7.854 3.756L12 4.362L9 7.284L9.708 11.412L6 9.462L2.292 11.412L3 7.284L0 4.362L4.146 3.756L6 0Z" fill="currentColor"></path>
                                      </svg>
                                   </span>
                                   <span>
                                      <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                         <path d="M6 0L7.854 3.756L12 4.362L9 7.284L9.708 11.412L6 9.462L2.292 11.412L3 7.284L0 4.362L4.146 3.756L6 0Z" fill="currentColor"></path>
                                      </svg>
                                   </span>
                                   <span>
                                      <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                         <path d="M6 0L7.854 3.756L12 4.362L9 7.284L9.708 11.412L6 9.462L2.292 11.412L3 7.284L0 4.362L4.146 3.756L6 0Z" fill="currentColor"></path>
                                      </svg>
                                   </span>
                                   <span>
                                      <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                         <path d="M6 0L7.854 3.756L12 4.362L9 7.284L9.708 11.412L6 9.462L2.292 11.412L3 7.284L0 4.362L4.146 3.756L6 0Z" fill="currentColor"></path>
                                      </svg>
                                   </span>
                                   <span>
                                      <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                         <path d="M6 0L7.854 3.756L12 4.362L9 7.284L9.708 11.412L6 9.462L2.292 11.412L3 7.284L0 4.362L4.146 3.756L6 0Z" fill="currentColor"></path>
                                      </svg>
                                   </span>
                               </div>
                           </div>
                           <h4 class="tp-shop-widget-product-title">
                              <a routerLink="/san-pham/{{ product.slug }}-tjk{{ product.id }}">
                                   {{ product.name }}
                                </a>
                           </h4>
                           <div class="tp-shop-widget-product-price-wrapper">
                              <span class="tp-shop-widget-product-price" *ngIf="product.priceSale > 0">
                                 {{ product.priceSale | number:'1.0-0' }} đ
                              </span>
                              <span class="tp-shop-widget-product-price" *ngIf="product.priceSale <= 0">
                                 Liên hệ
                              </span>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
         <div class="bg-white shadow-3 p-3 mt-5">
            <div class="title_page">Có thể bạn quan tâm</div>
            <div class="grid_container1" *ngIf="PRODUCT_RELATEDS">
               <div class="columns-md-2 columns-xs-6" *ngFor="let product of PRODUCT_RELATEDS" >
                  <div class="item_product">
                     <div class="sale" *ngIf="product.sale">{{ product.sale }}%</div>
                     <div class="images" *ngIf="product.image">
                        <a routerLink="/san-pham/{{ product.slug }}-tjk{{ product.id }}">
                           <img *ngIf="product.image.thumbnail; else usePhoto" appLazyLoad="{{ product.image.thumbnail }}" src="assets/images/400x400.png" alt="{{product.name}}" class="img-fluid" loading="lazy" (error)="onImageError($event)">
                           <!-- Nếu không có thumbnail thì hiển thị ảnh photo -->
                           <ng-template #usePhoto>
                           <img src="assets/images/no-image.jpg" alt="{{product.name}}" class="img-fluid">
                           </ng-template>
                        </a>
                     </div>
                     <div class="info p-4 py-2">
                        <div class="tp-product-category"><a routerLink="{{ product.categorie_first.slug }}-c{{ product.categorie_first.id }}">{{ product.categorie_first.name }}</a></div>
                        <a routerLink="/san-pham/{{ product.slug }}-tjk{{ product.id }}">
                              <h3>{{ product.name }}</h3>
                           </a>
                           <div class="tp-product-rating-icon">
                           <i class="fas fa-star"></i>
                           <i class="fas fa-star"></i>
                           <i class="fas fa-star"></i>
                           <i class="fas fa-star"></i>
                           <i class="fas fa-star"></i>
                        </div>
                        <div class="price">
                           <span *ngIf="product.price" class="me-3">{{ product.price | number:'1.0-0' }} đ</span>
                           <ng-container *ngIf="product.priceSale > 0; priceSale">{{ product.priceSale | number:'1.0-0' }} đ</ng-container>
                           <ng-template #priceSale>Liên hệ</ng-template>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
      
    </section>
   </section>
   }@error {
      <ng-template #notFoundTemplate>
         <div id="notfound">
             <div class="notfound">
                 <div class="notfound-404">
                     <h1>404!</h1>
                 </div>
                 <p>Xin lỗi, trang bạn tìm kiếm không tồn tại </p>
                 <div class="text-center"><a routerLink="/" class="btn btn-primary">Quay về trang chủ</a></div>
             </div>
         </div>
     </ng-template>
    }
    <!-- Template hiển thị trang 404 -->
    <ng-template #notFoundTemplate>
      <div id="notfound">
          <div class="notfound">
              <div class="notfound-404">
                  <h1>404!</h1>
              </div>
              <p>Xin lỗi, trang bạn tìm kiếm không tồn tại </p>
              <div class="text-center"><a routerLink="/" class="btn btn-primary">Quay về trang chủ</a></div>
          </div>
      </div>
  </ng-template>
    <!-- product details area end -->
 </main>