<main class="bg-gray-100 py-5">
    <div class="container">
        <div class="row">
            <div class="col-md-9 col-12">
                <div class="p-4 bg-white">
                    <h1 class="title_page">{{title}}</h1>
                    <div class="content" *ngIf="blogs">
                        <div class="tp-blog-item mb-3 row" *ngFor="let item of blogs">
                            <div class="tp-blog-thumb p-relative fix mb-2 col-md-3 col-12">
                                <a [routerLink]="[item.slug]">
                                    <img *ngIf="item.image; else usePhoto" [src]="item.image.thumbnail" alt="{{item.name}}" class="img-fluid">
                                    <!-- Nếu không có thumbnail thì hiển thị ảnh photo -->
                                    <ng-template #usePhoto>
                                        <img src="assets/images/no-image.jpg" alt="{{item.name}}" class="img-fluid">
                                    </ng-template>
                                </a>
                            </div>
                            <div class="tp-blog-content col-md-9 col-12">
                                <h3 class="tp-blog-title">
                                    <a [routerLink]="[item.slug+ '-p' + item.id+'.html']">{{item.name}}</a>
                                </h3>
                                <div class="tp-blog-meta-date"><i class="fas fa-calendar-alt"></i> {{ item.created_at }}</div>
                                <p>{{item.mota}}</p>
                                <div class="tp-blog-btn">
                                    <a [routerLink]="[item.slug+ '-p' + item.id+'.html']" class="tp-btn-2 tp-btn-border-2">
                                    Xem thêm 
                                    <span>
                                        <svg width="17" height="15" viewBox="0 0 17 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M16 7.5L1 7.5" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                                            <path d="M9.9502 1.47541L16.0002 7.49941L9.9502 13.5244" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                                        </svg>                                       
                                    </span>
                                    </a>
                                </div>
                            </div>                    
                        </div>
                        <ngb-pagination *ngIf="totalPages/16>1"
                            [collectionSize]="totalPages"
                            [(page)]="currentPage"
                            [pageSize]="16"
                            [rotate]="true"
                            [boundaryLinks]="true"
                            (pageChange)="loadPage($event)"
                            [maxSize]="5"
                        ></ngb-pagination>
                    </div>
                </div>
                
            </div>
            <div class="col-md-3 col-12"><app-left-product></app-left-product></div>
        </div>
    </div>
</main>