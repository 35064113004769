import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { URL_SERVICEIO } from '../../../config/config';
import { tap } from 'rxjs';
import { SettingsService } from '../../../services/settings.service';

@Injectable({
  providedIn: 'root'
})
export class NewsService {

  constructor(
    private http: HttpClient, private settingsService: SettingsService
  ) { }
  getNews(page:number,type:string){
    let URL= URL_SERVICEIO+"/frontEnd/news?page="+page+"&type="+type;
    return this.http.get(URL);
  }
  getNewsCategories(page:number,id:string){
    let URL= URL_SERVICEIO+"/frontEnd/news/"+id+"?page="+page;
    return this.http.get(URL).pipe(
      tap((resp: any) => {
        this.settingsService.settings$.subscribe((settings:any) => {
          const title_custom = resp?.categorie?.title ? resp.categorie?.title : resp?.categorie?.name;
          // Kiểm tra description, nếu product.description rỗng thì lấy setting.description
          const description_custom = resp?.categorie?.description ? resp?.categorie?.description : settings?.description;
          // Kiểm tra image, nếu product.image rỗng thì lấy setting.photo
          const imageUrl = resp?.categorie?.image ? resp.categorie?.image?.photo : settings?.logo;
          this.settingsService.updateMetaTags(title_custom, description_custom, imageUrl, settings.title);
        });
      })
    );
  }
  getNewsDetail(categorySlug:string, id:string){
    let URL=URL_SERVICEIO+"/frontEnd/news/"+categorySlug+"/"+id;
    return this.http.get(URL).pipe(
      tap((resp: any) => {
        this.settingsService.settings$.subscribe((settings:any) => {
          const title_custom = resp.newsDetail?.title ? resp.newsDetail?.title : resp.newsDetail?.name;
          // Kiểm tra description, nếu product.description rỗng thì lấy setting.description
          const description_custom = resp.newsDetail?.description ? resp.newsDetail?.description : settings?.description;
          // Kiểm tra image, nếu product.image rỗng thì lấy setting.photo
          const imageUrl = resp.newsDetail?.image ? resp.newsDetail?.image?.photo : settings?.logo;
          this.settingsService.updateMetaTags(title_custom, description_custom, imageUrl, settings.title);
        });
      })
    );
  }
}
