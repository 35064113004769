import { Component } from '@angular/core';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { NewsService } from '../service/news.service';
import { CommonModule } from '@angular/common';
import { NgbPaginationModule } from '@ng-bootstrap/ng-bootstrap';
import { LeftProductComponent } from '../../product/layout/left-product/left-product.component';
import { ImagesLazyloadModule } from '../../../shared/images-lazyload/images-lazyload.module';

@Component({
  selector: 'app-categories-news',
  standalone: true,
  imports: [RouterModule, CommonModule, NgbPaginationModule, LeftProductComponent, ImagesLazyloadModule],
  templateUrl: './categories-news.component.html',
  styleUrl: './categories-news.component.css'
})
export class CategoriesNewsComponent {
  title:string='';
  type:string='';
  com:string='';
  settings:any=[];
  news:any=[];
  categorie:any=[];
  totalPages:number = 0;
  currentPage:number = 1;
  id_categorie:string = '';
  isContentExpanded: boolean = false;
  constructor(
    private activedRoute: ActivatedRoute,
    private router: Router,
    private newsService: NewsService,
  ){  }
  ngOnInit(): void {
    this.activedRoute.queryParams.subscribe(params => {
      this.currentPage = params['page'] ? +params['page'] : 1;
    });
    this.activedRoute.data.subscribe(data => {
      this.type = data['type'];
      this.com = data['com'];
    });
    this.activedRoute.params.subscribe(data => {
      this.id_categorie=data["slugDanhmuc"];
      this.listNews(this.currentPage);
    })
  }
  listNews(page=1){
    this.newsService.getNewsCategories(page, this.id_categorie).subscribe((resp:any)=>{console.log(resp);
      this.news=resp.news;
      this.totalPages=resp.total;
      this.currentPage=page;
      this.categorie=resp.categorie;
      this.title = (resp.categorie) ? resp.categorie.name : '';
    })
  }
  loadPage(page: number): void {
    this.currentPage = page;
  
    // Cập nhật URL với tham số page
    this.router.navigate([], {
      relativeTo: this.activedRoute,
      queryParams: { page: this.currentPage },
      queryParamsHandling: 'merge' // Giữ lại các query params khác nếu có
    });
  
    // Thực hiện tải dữ liệu hoặc các hành động khác cho trang mới
    this.listNews(page);
  }
  showMore() {
    this.isContentExpanded = !this.isContentExpanded; // Đảo trạng thái
  }
}
