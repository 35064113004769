<main class="bg-gray-100 py-3">
    <div class="container">
        <div class="row">
            <div class="col-md-3 col-12">
               <div class="tp-shop-widget mb-5 bg-white p-3">
                  <h3 class="tp-shop-widget-title">Danh mục sản phẩm</h3>
              
                  <div class="tp-shop-widget-content">
                     <div class="tp-shop-widget-categories">
                        <ul>
                           <li *ngFor="let item of categories"><a routerLink="/{{ item.slug }}/{{ brand.slug }}">{{ item.name }}</a></li>
                        </ul>
                     </div>
                  </div>
              </div>
              <!-- product rating -->
               <div class="tp-shop-widget mb-6 d-md-block d-none bg-white p-3">
                  <h3 class="tp-shop-widget-title">Sản phẩm bán chạy</h3>

                  <div class="tp-shop-widget-content">
                     <div class="tp-shop-widget-product">
                        <div class="tp-shop-widget-product-item d-flex align-items-center mb-3 border-bottom pb-3" *ngFor="let product of productSale">
                           <div class="tp-shop-widget-product-thumb col-3">
                           <a routerLink="/san-pham/{{ product.slug }}-tjk{{ product.id }}">
                              <img *ngIf="product.image.thumbnail; else usePhoto" [src]="product.image.thumbnail" alt="{{product.name}}" class="img-fluid">
                              <!-- Nếu không có thumbnail thì hiển thị ảnh photo -->
                              <ng-template #usePhoto>
                              <img src="assets/images/no-image.jpg" alt="{{product.name}}" class="img-fluid">
                              </ng-template>
                              </a>
                           </div>
                           <div class="tp-shop-widget-product-content col-9 ps-3">
                              <div class="tp-shop-widget-product-rating-wrapper d-flex align-items-center">
                                 <div class="tp-shop-widget-product-rating">
                                    <span>
                                       <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                          <path d="M6 0L7.854 3.756L12 4.362L9 7.284L9.708 11.412L6 9.462L2.292 11.412L3 7.284L0 4.362L4.146 3.756L6 0Z" fill="currentColor"></path>
                                       </svg>
                                    </span>
                                    <span>
                                       <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                          <path d="M6 0L7.854 3.756L12 4.362L9 7.284L9.708 11.412L6 9.462L2.292 11.412L3 7.284L0 4.362L4.146 3.756L6 0Z" fill="currentColor"></path>
                                       </svg>
                                    </span>
                                    <span>
                                       <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                          <path d="M6 0L7.854 3.756L12 4.362L9 7.284L9.708 11.412L6 9.462L2.292 11.412L3 7.284L0 4.362L4.146 3.756L6 0Z" fill="currentColor"></path>
                                       </svg>
                                    </span>
                                    <span>
                                       <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                          <path d="M6 0L7.854 3.756L12 4.362L9 7.284L9.708 11.412L6 9.462L2.292 11.412L3 7.284L0 4.362L4.146 3.756L6 0Z" fill="currentColor"></path>
                                       </svg>
                                    </span>
                                    <span>
                                       <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                          <path d="M6 0L7.854 3.756L12 4.362L9 7.284L9.708 11.412L6 9.462L2.292 11.412L3 7.284L0 4.362L4.146 3.756L6 0Z" fill="currentColor"></path>
                                       </svg>
                                    </span>
                                 </div>
                              </div>
                              <h4 class="tp-shop-widget-product-title">
                              <a [routerLink]="['/san-pham', product.categorie_third?.slug || product.categorie_second?.slug || product.categorie_first?.slug, product.slug]">
                                    {{ product.name }}
                                 </a>
                              </h4>
                              <div class="tp-shop-widget-product-price-wrapper">
                                 <span class="tp-shop-widget-product-price" *ngIf="product.priceSale > 0">
                                    {{ product.priceSale | number:'1.0-0' }} đ
                                 </span>
                                 <span class="tp-shop-widget-product-price" *ngIf="product.priceSale <= 0">
                                    Liên hệ
                                 </span>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
            <div class="col-md-9 col-12">
               <section class="breadcrumb__area include-bg mb-3">
                  <div class="breadcrumb__content position-relative z-index-1">
                     <div class="breadcrumb__list mb-3">
                        <span><a routerLink="/">Trang chủ</a></span>
                        <span>{{ brand.name }}</span>
                     </div>
                     <h1 class="breadcrumb__title">{{brand.name}}</h1>
                  </div>
               </section>
               <div class="grid_container">
                  <div class="columns-md-3 columns-xs-6 mb-4 item_product bg-white" *ngFor="let product of products">
                     <div class="sale" *ngIf="product.sale">{{ product.sale }}%</div>
                     <div class="images">
                        <a routerLink="/san-pham/{{ product.slug }}-tjk{{ product.id }}">
                           <img *ngIf="product.image; else usePhoto" appLazyLoad="{{ product.image.thumbnail }}" src="assets/images/400x400.png" alt="{{product.name}}" class="img-fluid" loading="lazy">
                           <!-- Nếu không có thumbnail thì hiển thị ảnh photo -->
                           <ng-template #usePhoto>
                           <img src="assets/images/no-image.jpg" alt="{{product.name}}" class="img-fluid">
                           </ng-template>
                        </a>
                     </div>
                     <div class="info p-4 py-2">
                        <div class="tp-product-category"><a routerLink="{{ product.categorie_first.slug }}-c{{ product.categorie_first.id }}">{{ product.categorie_first.name }}</a></div>
                        <a routerLink="/san-pham/{{ product.slug }}-tjk{{ product.id }}">
                           <h3>{{ product.name }}</h3>
                        </a>
                        <div class="tp-product-rating-icon">
                        <i class="fas fa-star"></i>
                        <i class="fas fa-star"></i>
                        <i class="fas fa-star"></i>
                        <i class="fas fa-star"></i>
                        <i class="fas fa-star"></i>
                        </div>
                        <div class="price">
                           <span *ngIf="product.price" class="me-3">{{ product.price | number:'1.0-0' }} đ</span>
                           <strong *ngIf="product.priceSale > 0">
                              {{ product.priceSale | number:'1.0-0' }} đ
                           </strong>
                           <strong *ngIf="product.priceSale <= 0">
                              Liên hệ
                           </strong>
                        </div>
                     </div>
                  </div>
              </div>
                  <ngb-pagination *ngIf="totalPages/20>1"
                     [collectionSize]="totalPages"
                     [(page)]="currentPage"
                     [pageSize]="20"
                     [rotate]="true"
                     [boundaryLinks]="true"
                     (pageChange)="loadPage($event)"
                     [maxSize]="5"
               ></ngb-pagination>
               <div class="contentDetail mh-200 bg-white p-3 my-3" *ngIf="brand.content" [ngStyle]="{'height': isContentExpanded ? 'auto' : '200px'}">
                  <div class="content" [innerHTML]="brand.content"></div>
                  <div class="viewMore">
                      <a class="btn btn_readmore ripple-surface" (click)="showMore()" [class.active]="isContentExpanded"><span>{{ isContentExpanded ? 'Rút gọn' : 'Xem thêm' }}</span> <svg class="svg svg-more-down ml-3" xmlns="http://www.w3.org/2000/svg" width="9" height="6" viewBox="0 0 9 6"><path fill="none" fill-rule="evenodd" stroke="#08711c" stroke-linecap="round" stroke-width="2" d="M7.5 1.5l-3 3-3-3"></path></svg></a>
                  </div>
              </div>
            </div>
        </div>
    </div>
</main>