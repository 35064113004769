import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { URL_SERVICEIO, URL_WEBSITE } from '../config/config';
import { BehaviorSubject, Observable } from 'rxjs';
import { Title, Meta } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root'
})
export class SettingsService {
  private settingsSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  public settings$: Observable<any> = this.settingsSubject.asObservable();

  private apiUrl = URL_SERVICEIO+"/frontEnd/setting"; // Thay bằng URL API của bạn

  constructor(private http: HttpClient,
    private titleService: Title,
    private metaService: Meta,
  ) {}

  loadSettings(): void {
    this.http.get(this.apiUrl).subscribe(
      (resp: any) => {
        this.settingsSubject.next(resp.setting);
      },
      (error) => {
        console.error('Error fetching settings:', error);
      }
    );
  }

  public updateMetaTags(title:string, description: string, photo: string, titleSite:string) {
    
    this.titleService.setTitle(title+" - "+URL_WEBSITE);
    this.metaService.updateTag({ property: 'og:site_name', content: title+" - "+URL_WEBSITE });
    this.metaService.updateTag({ name: 'description', content: description });
    this.metaService.updateTag({ property: 'og:title', content: title+" - "+URL_WEBSITE });
    this.metaService.updateTag({ property: 'og:description', content: description });
    this.metaService.updateTag({ property: 'og:image', content: photo });

    this.metaService.updateTag({ name: 'twitter:card', content: 'summary_large_image' });
    this.metaService.updateTag({ name: 'twitter:title', content: title+" - "+URL_WEBSITE });
    this.metaService.updateTag({ name: 'twitter:description', content: description });
    this.metaService.updateTag({ name: 'twitter:image', content: photo });
  }
}