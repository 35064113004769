import { Component} from '@angular/core';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { HomeService } from '../../home/service/home.service';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { NgbPaginationModule } from '@ng-bootstrap/ng-bootstrap';
import { LeftProductComponent } from '../../product/layout/left-product/left-product.component';
import { SettingsService } from '../../../services/settings.service';

@Component({
  selector: 'app-list-article',
  standalone: true,
  imports: [CommonModule, FormsModule,NgbPaginationModule, RouterModule, LeftProductComponent],
  templateUrl: './list-article.component.html',
  styleUrl: './list-article.component.css'
})
export class ListArticleComponent {
  title:string='';
  type:string='';
  com:string='';
  blogs:any=[];
  settings:any=[];
  totalPages:number = 0;
  currentPage:number = 1;
  constructor(
    private homeService: HomeService,
    public activedRoute: ActivatedRoute,
    private router: Router,
    private settingsService: SettingsService,
  ){  }
  ngOnInit(): void {
    this.activedRoute.data.subscribe(data => {
      this.type = data['type'];
      this.title = data['title'];
      this.com = data['com'];
      this.settingsService.settings$.subscribe((settings:any) => {
        this.settingsService.updateMetaTags(this.title, settings.description, settings.logo, settings.title);
      });
      
    });
    this.activedRoute.queryParams.subscribe(params => {
      this.currentPage = params['page'] ? +params['page'] : 1;
      this.listBlogs(this.currentPage); // Tải dữ liệu cho trang hiện tại
    });
  }
  listBlogs(page=1){
    this.homeService.getAbouts(page,this.type).subscribe((resp:any)=>{
      this.blogs=resp.blogs;
      this.totalPages=resp.total;
      this.currentPage=page;
    })
  }
  loadPage(page: number): void {
    this.currentPage = page;
  
    // Cập nhật URL với tham số page
    this.router.navigate([], {
      relativeTo: this.activedRoute,
      queryParams: { page: this.currentPage },
      queryParamsHandling: 'merge' // Giữ lại các query params khác nếu có
    });
  
    // Thực hiện tải dữ liệu hoặc các hành động khác cho trang mới
    this.listBlogs(page);
  }
}
