import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { Router, RouterModule } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from '../service/auth.service';

@Component({
  selector: 'app-change-pass',
  standalone: true,
  imports: [RouterModule, CommonModule, FormsModule],
  templateUrl: './change-pass.component.html',
  styleUrl: './change-pass.component.css'
})
export class ChangePassComponent {
  user: any = null;
  token: string = '';
  email: string ="";
  password: string ="";
  newpassword: string ="";
  renewpassword: string ="";
  isLoading: boolean = false;

  constructor(
    private authService: AuthService,
    private toastr: ToastrService,
    public router: Router
  ) {}

  ngOnInit(): void {
    this.user = this.authService.user;
    this.token = this.authService.token;

    if(!this.token && !this.user){
      setTimeout(() => {
        this.router.navigateByUrl("/login");
      }, 500);
      return;
    }
    this.email=this.user.email;
  }
  changePass(){
    this.isLoading = true;
    if(!this.newpassword || !this.renewpassword){
      this.toastr.error("Lỗi","Vui lòng nhập đầy đủ thông tin");
      this.isLoading = false;
      return;
    }
    if(this.newpassword!=this.renewpassword){
      this.toastr.error("Lỗi", "Nhập lại mật khẩu không đúng.");
      this.isLoading = false;
      return;
    }
    let data={
      password : this.newpassword,
      password_confirmation:this.renewpassword,
      email: this.email
    }
    this.authService.verifiedNewPass(data).subscribe((resp:any) =>{
      if(resp.code==200){        
        this.toastr.success("Thông báo",resp.message);
        this.authService.logout();
      }else{
        this.isLoading = false;
        this.toastr.error("Lỗi",resp.message);
      }
    },(error)=>{
      this.isLoading = false;
      this.toastr.error("Lỗi", error.error.message)
    })
  }
}
