<main class="bg-gray-100 py-3">
    <div class="container bg-white p-3">
        <div class="content1x postDetail">
            <h1 class="title">{{ blog.name }}</h1>
            <div class="date">
                <i class="fas fa-calendar-alt"></i> {{ blog.created_at }}
            </div>
            <div class="my-3" *ngIf="blog.content" [innerHTML]="blog.content"></div>
        </div>
        
    </div>
</main>