<!-- footer area start -->
<footer class=" mt-3">
    <div class="tp-footer-area" data-bg-color="footer-bg-grey">
       <div class="tp-footer-top pt-95 pb-40">
          <div class="container">
             <div class="row">
                <div class="col-xl-4 col-lg-3 col-md-4 col-sm-6 mb-6">
                   <div class="tp-footer-widget footer-col-1 mb-50">
                     <h4 class="tp-footer-widget-title">{{ footer.name }}</h4>
                      <div class="tp-footer-widget-content">
                         <div class="tp-footer-desc" [innerHTML]="footer.content"></div>
                         <div class="tp-footer-social">
                            <a *ngIf="settings" href="{{settings.facebook}}" rel="nofollow" target="_blank"><i class="fab fa-facebook-f"></i></a>
                            <a *ngIf="settings" href="{{settings.twitter}}" rel="nofollow" target="_blank"><i class="fab fa-twitter"></i></a>
                            <a *ngIf="settings" href="{{settings.tiktok}}" rel="nofollow" target="_blank"><i class="fab fa-tiktok"></i></a>
                            <a *ngIf="settings" href="{{settings.instagram}}" rel="nofollow" target="_blank"><i class="fab fa-instagram"></i></a>
                         </div>
                      </div>
                   </div>
                </div>
                <div class="col-xl-3 col-lg-3 col-md-4 col-sm-6 col-6 mb-4">
                   <div class="tp-footer-widget footer-col-3 mb-50">
                      <h4 class="tp-footer-widget-title">Về chúng tôi</h4>
                      <div class="tp-footer-widget-content">
                         <ul>
                            <li><a routerLink="/gioi-thieu">Giới thiệu</a></li>
                            <li><a routerLink="/tuyen-dung">Tuyển dụng</a></li>
                            <li><a routerLink="/chinh-sach-bao-mat">Chính sách bảo mật</a></li>
                            <li><a routerLink="/dieu-khoan">Điều khoản và điều kiện</a></li>
                            <li><a routerLink="tin-tuc">Tin tức</a></li>
                            <li><a routerLink="lien-he">Liên hệ</a></li>
                         </ul>
                      </div>
                   </div>
                </div>
                <div class="col-xl-2 col-lg-3 col-md-4 col-sm-6 col-6 mb-4">
                  <div class="tp-footer-widget footer-col-2 mb-50">
                     <h4 class="tp-footer-widget-title">Hỗ trợ khách hàng</h4>
                     <div class="tp-footer-widget-content">
                        <ul>
                           <li *ngFor="let item of support"><a routerLink="ho-tro/{{ item.slug }}">{{ item.name }}</a></li>
                           <li><a routerLink="faqs">Câu hỏi thường gặp</a></li>
                        </ul>
                     </div>
                  </div>
               </div>
                <div class="col-xl-3 col-lg-3 col-md-4 col-sm-6 mb-4">
                   <div class="tp-footer-widget footer-col-4 mb-50">
                      <h4 class="tp-footer-widget-title">Liên hệ chúng tôi</h4>
                      <div class="tp-footer-widget-content">
                         <div class="tp-footer-talk mb-20">
                            <span>Có thắc mắc? Gọi cho chúng tôi</span>
                            <h4 *ngIf="settings"><a href="tel:{{ settings.hotline }}">{{ settings.hotline }}</a></h4>
                         </div>
                         <div class="tp-footer-contact">
                            <div class="tp-footer-contact-item d-flex align-items-start">
                               <div class="tp-footer-contact-icon me-3">
                                  <span>
                                     <svg width="18" height="16" viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M1 5C1 2.2 2.6 1 5 1H13C15.4 1 17 2.2 17 5V10.6C17 13.4 15.4 14.6 13 14.6H5" stroke="currentColor" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                                        <path d="M13 5.40039L10.496 7.40039C9.672 8.05639 8.32 8.05639 7.496 7.40039L5 5.40039" stroke="currentColor" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                                        <path d="M1 11.4004H5.8" stroke="currentColor" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                                        <path d="M1 8.19922H3.4" stroke="currentColor" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                                     </svg>
                                  </span>
                               </div>
                               <div class="tp-footer-contact-content">
                                 <p *ngIf="settings"><a href="mailto:{{ settings.email }}">{{ settings.email }}</a></p>
                               </div>
                            </div>
                            <div class="tp-footer-contact-item d-flex align-items-start">
                               <div class="tp-footer-contact-icon me-3">
                                  <span>
                                     <svg width="17" height="20" viewBox="0 0 17 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M8.50001 10.9417C9.99877 10.9417 11.2138 9.72668 11.2138 8.22791C11.2138 6.72915 9.99877 5.51416 8.50001 5.51416C7.00124 5.51416 5.78625 6.72915 5.78625 8.22791C5.78625 9.72668 7.00124 10.9417 8.50001 10.9417Z" stroke="currentColor" stroke-width="1.5"/>
                                        <path d="M1.21115 6.64496C2.92464 -0.887449 14.0841 -0.878751 15.7889 6.65366C16.7891 11.0722 14.0406 14.8123 11.6313 17.126C9.88298 18.8134 7.11704 18.8134 5.36006 17.126C2.95943 14.8123 0.210885 11.0635 1.21115 6.64496Z" stroke="currentColor" stroke-width="1.5"/>
                                     </svg>
                                  </span>
                               </div>
                               <div class="tp-footer-contact-content">
                                  <p *ngIf="settings">{{ settings.address }}</p>
                               </div>
                            </div>
                         </div>
                      </div>
                   </div>
                </div>
             </div>
          </div>
       </div>
    </div>
  </footer>
  <!-- footer area end -->