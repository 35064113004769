import { Component, Inject, PLATFORM_ID, afterNextRender } from '@angular/core';
import { CommonModule, isPlatformBrowser } from '@angular/common';
import { NavigationEnd, Router, RouterModule, RouterOutlet } from '@angular/router';
import { HeaderComponent } from './shared/header/header.component';
import { FooterComponent } from './shared/footer/footer.component';
import { SettingsService } from './services/settings.service';
import { FormsModule } from '@angular/forms';

declare var $:any;
declare function homeInit([]):any;
@Component({
  selector: 'app-root',
  standalone: true,
  imports: [CommonModule, RouterOutlet, HeaderComponent, FooterComponent,RouterModule,FormsModule],
  templateUrl: './app.component.html',
  styleUrl: './app.component.css'
})
export class AppComponent {
  title = 'frontEnd';
  codeInput: string = '';  // Mã code mà người dùng nhập
  correctCode: string = '';  // Mã code chính xác (có thể lấy từ API hoặc thiết lập tĩnh)
  isAuthenticated: boolean = false;  // Trạng thái xác thực
  errorMessage: string = '';  // Thông báo lỗi
  tokenExpiryTime: number = 86400000;  // Thời gian hết hạn (1 ngày = 86400000ms)


  constructor(
    private settingsService: SettingsService,
    private router: Router,
    @Inject(PLATFORM_ID) private platformId: Object,
  ){
    afterNextRender(()=>{
      $(window).on("load",function(){
        $("#loading").fadeOut(500);
      })
    })
    
  }
  ngOnInit(): void {
    //Called after the constructor, initializing input properties, and the first call to ngOnChanges.
    //Add 'implements OnInit' to the class.
    this.settingsService.loadSettings();
    this.settingsService.settings$.subscribe(
      (data) => {
        if (data && data.code) {
          this.correctCode = data.code;
        } else {
          this.correctCode = 'Tajako@97618'; // Giá trị mặc định nếu không có code
        }
      }
    );
    this.checkAuthentication();
    if (isPlatformBrowser(this.platformId)) {
      this.router.events.subscribe((event) => {
        if (event instanceof NavigationEnd) {
          window.scrollTo(0, 0); // Cuộn trang lên đầu
        }
      });
    }
  }

  checkAuthentication(): void {
    if (isPlatformBrowser(this.platformId)) {
      const storedCodeData = localStorage.getItem('authCode');
      if (storedCodeData) {
        const parsedData = JSON.parse(storedCodeData);
        const now = new Date().getTime();

        // Kiểm tra xem mã code có còn hiệu lực không
        if (now < parsedData.expiry) {
          this.isAuthenticated = true;  // Xác thực thành công, mã còn hiệu lực
        } else {
          localStorage.removeItem('authCode');  // Xóa mã code nếu hết hạn
          this.isAuthenticated = false;
        }
      } else {
        this.isAuthenticated = false;  // Nếu không có mã code, yêu cầu nhập
      }
    }
  }

  // Phương thức kiểm tra mã code khi người dùng nhập
  checkCode(): void {
    if (isPlatformBrowser(this.platformId)) {
      if (this.codeInput === this.correctCode) {
        this.isAuthenticated = true;
        this.errorMessage = '';

        // Lưu mã code và thời gian hết hạn vào localStorage
        const now = new Date().getTime();
        const expiry = now + this.tokenExpiryTime;  // Thời gian hết hạn là 1 ngày

        const authData = {
          code: this.codeInput,
          expiry: expiry
        };

        localStorage.setItem('authCode', JSON.stringify(authData));  // Lưu vào localStorage
      } else {
        this.errorMessage = 'Mã code không đúng. Vui lòng thử lại!';
      }
    }
  }
}
