import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { URL_SERVICEIO } from '../../../config/config';
import { Observable, shareReplay, tap } from 'rxjs';
import { SettingsService } from '../../../services/settings.service';
import { isPlatformBrowser } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class HomeService {
  private footer$: Observable<any> | undefined;
  private menus$: Observable<any> | undefined;
  constructor(
    private http: HttpClient,
    private settingsService: SettingsService,
    @Inject(PLATFORM_ID) private platformId: object
  ) { }

  slider(){
    let URL= URL_SERVICEIO+"/frontEnd/slider";
    return this.http.get(URL);
  }
  home(){
    let URL= URL_SERVICEIO+"/frontEnd/home";
    return this.http.get(URL).pipe(
      tap((resp: any) => {
        this.settingsService.settings$.subscribe((settings:any) => {
          const title_custom = settings?.title ? settings?.title : settings?.name;
          // Kiểm tra description, nếu product.description rỗng thì lấy setting.description
          const description_custom = settings?.description;
          // Kiểm tra image, nếu product.image rỗng thì lấy setting.photo
          const imageUrl = settings?.logo;
          this.settingsService.updateMetaTags(title_custom, description_custom, imageUrl, settings.title);
          if (isPlatformBrowser(this.platformId)) {
            this.addProductSchema();
          }
        });
      })
    );
    //return this.http.get(URL);
  }
  menus(){
    let URL= URL_SERVICEIO+"/frontEnd/menus";
    if (!this.menus$) {
      this.menus$ = this.http.get(URL).pipe(
        shareReplay(1)
      );
    }
    return this.menus$;
  }
  footer(){
    let URL = URL_SERVICEIO+"/frontEnd/footer";
    //return this.http.get(URL);
    if (!this.footer$) {
      this.footer$ = this.http.get(URL).pipe(
        shareReplay(1)
      );
    }
    return this.footer$;
  }

  formatCurrency(value: number): string {
    return value.toLocaleString('vi-VN', { style: 'currency', currency: 'VND' }).replace('₫', 'đ');
  }

  getAbouts(page:number,type:string){
    let URL= URL_SERVICEIO+"/frontEnd/blogs?page="+page+"&type="+type;
    return this.http.get(URL);
  }
  getAboutDetail(id:string){
    let URL=URL_SERVICEIO+"/frontEnd/blogs/"+id;
    return this.http.get(URL).pipe(
      tap((resp: any) => {
        this.settingsService.settings$.subscribe((settings:any) => {
          const title_custom = resp.blog?.title ? resp.blog?.title : resp.blog?.name;
          // Kiểm tra description, nếu product.description rỗng thì lấy setting.description
          const description_custom = resp.blog?.description ? resp.blog?.description : settings?.description;
          // Kiểm tra image, nếu product.image rỗng thì lấy setting.photo
          const imageUrl = resp.blog?.image ? resp.blog?.image?.photo : settings?.logo;
          this.settingsService.updateMetaTags(title_custom, description_custom, imageUrl, settings.title);
        });
      })
    );
  }
  getArticleDetail(id:string){
    let URL=URL_SERVICEIO+"/frontEnd/article/"+id;
    return this.http.get(URL).pipe(
      tap((resp: any) => {
        this.settingsService.settings$.subscribe((settings:any) => {
          const title_custom = resp.article?.title ? resp.article?.title : resp.article?.name;
          // Kiểm tra description, nếu product.description rỗng thì lấy setting.description
          const description_custom = resp.article?.description ? resp.article?.description : settings?.description;
          // Kiểm tra image, nếu product.image rỗng thì lấy setting.photo
          const imageUrl = resp.article?.photo ? resp.article?.photo : settings?.logo;
          this.settingsService.updateMetaTags(title_custom, description_custom, imageUrl, settings.title);
        });
      })
    );
    //return this.http.get(URL);
  }
  sendContact(data:any){
    let URL=URL_SERVICEIO+"/frontEnd/contact";
    return this.http.post(URL, data);
  }

  addProductSchema(): void {
    const schema = {
      "@context": "https://schema.org",
      "@type": "WebSite",
      "name": "Tên Website",
      "url": "https://www.example.com",
      "potentialAction": {
        "@type": "SearchAction",
        "target": "https://www.example.com/search?q={search_term_string}",
        "query-input": "required name=search_term_string"
      }
    };

    // Append schema to head
    const scriptTag = document.createElement('script');
    scriptTag.type = 'application/ld+json';
    scriptTag.text = JSON.stringify(schema);
    document.head.appendChild(scriptTag);
  }
}
