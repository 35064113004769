<main class="bg-gray-100 py-3">
   <app-slider></app-slider>
   <div class="container">
      <div class="productHot mb-5 bg-white p-3">
         <h2 class="title_page">Sản phẩm bán chạy</h2>
         <div class="owl4">
            <div class="item_product" *ngFor="let product of productSale">
               <div class="sale" *ngIf="product.sale">{{ product.sale }}%</div>
               <div class="images">
                  <a routerLink="/san-pham/{{ product.slug }}-tjk{{ product.id }}">
                     <img *ngIf="product.image.thumbnail; else usePhoto" appLazyLoad="{{ product.image.thumbnail }}" src="assets/images/400x400.png" alt="{{product.name}}" class="img-fluid" loading="lazy">
                     <!-- Nếu không có thumbnail thì hiển thị ảnh photo -->
                     <ng-template #usePhoto>
                     <img data-src="assets/images/no-image.jpg" alt="{{product.name}}" class="img-fluid" loading="lazy">
                     </ng-template>
                  </a>
               </div>
               <div class="info p-4 py-2">
                  <div class="tp-product-category"><a routerLink="{{ product.categorie_first.slug }}-c{{ product.categorie_first.id }}">{{ product.categorie_first.name }}</a></div>
                  <a routerLink="/san-pham/{{ product.slug }}-tjk{{ product.id }}">
                     <h3>{{ product.name }}</h3>
                  </a>
                  <div class="tp-product-rating-icon">
                     <i class="fas fa-star"></i>
                     <i class="fas fa-star"></i>
                     <i class="fas fa-star"></i>
                     <i class="fas fa-star"></i>
                     <i class="fas fa-star"></i>
                  </div>
                  <div class="price">
                     <span *ngIf="product.price" class="me-3">{{ product.price | number:'1.0-0' }} đ</span>
                     <strong *ngIf="product.priceSale > 0">
                        {{ product.priceSale | number:'1.0-0' }} đ
                     </strong>
                     <strong *ngIf="product.priceSale <= 0">
                        Liên hệ
                     </strong>
                  </div>
               </div>
            </div>
         </div>
      </div>
      <div class="group_product mb-5 bg-white p-3" *ngFor="let categorie of categories">
         <h2 class="title_page mt-3">{{ categorie.name }}</h2>
         <div class="grid_container1">
            <div class="columns-md-2 columns-xs-6 item_product" *ngFor="let product of categorie.products" >
               <div class="sale" *ngIf="product.sale">{{ product.sale }}%</div>
               <div class="images">
                  <a routerLink="/san-pham/{{ product.slug }}-tjk{{ product.id }}">
                     <img *ngIf="product.image.thumbnail; else usePhoto" appLazyLoad="{{ product.image.thumbnail }}" src="assets/images/400x400.png" alt="{{product.name}}" class="img-fluid" loading="lazy">
                     <!-- Nếu không có thumbnail thì hiển thị ảnh photo -->
                     <ng-template #usePhoto>
                     <img src="assets/images/no-image.jpg" alt="{{product.name}}" class="img-fluid" loading="lazy">
                     </ng-template>
                  </a>
               </div>
               <div class="info p-4 py-2">
                  <div class="tp-product-category"><a routerLink="{{ product.categorie_first.slug }}-c{{ product.categorie_first.id }}">{{ product.categorie_first.name }}</a></div>
                  <a routerLink="/san-pham/{{ product.slug }}-tjk{{ product.id }}">
                     <h3>{{ product.name }}</h3>
                  </a>
                  <div class="tp-product-rating-icon">
                     <i class="fas fa-star"></i>
                     <i class="fas fa-star"></i>
                     <i class="fas fa-star"></i>
                     <i class="fas fa-star"></i>
                     <i class="fas fa-star"></i>
                  </div>
                  <div class="price">
                     <span *ngIf="product.price" class="me-3">{{ product.price | number:'1.0-0' }} đ</span>
                     <strong *ngIf="product.priceSale > 0">
                        {{ product.priceSale | number:'1.0-0' }} đ
                     </strong>
                     <strong *ngIf="product.priceSale <= 0">
                        Liên hệ
                     </strong>
                  </div>
               </div>
            </div>
         </div>
         <div class="text-center my-3">
            <a routerLink="{{ categorie.slug }}-c{{ categorie.id }}" class="readMore">Xem thêm <span>
                                 <svg width="17" height="15" viewBox="0 0 17 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M16 7.5L1 7.5" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                                    <path d="M9.9502 1.47541L16.0002 7.49941L9.9502 13.5244" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                                 </svg>
                              </span></a>
         </div>
      </div>

      <div class="uudai bg-white my-3 p-3">
         <div class="grid_container">
            <div class="columns-md-2 columns-xs-6 itemUudai">
               <img alt="Chính hãng 100%" class="img-fluid lazyload" width="60px" height="60px" src="assets/images/vanchuyen/quality.png.webp" style="display: inline;">
               <div class="name">Chính hãng 100%</div>
               <p>Sản phẩm chính hãng, nguồn gốc rõ ràng</p>
            </div>
            <div class="columns-md-2 columns-xs-6 itemUudai">
               <img alt="Giá tốt nhất" class="img-fluid lazyload" width="60px" height="60px" src="assets/images/vanchuyen/best-price.png.webp" style="display: inline;">
               <div class="name">Giá tốt nhất</div>
               <p>Yên tâm mua sắm, giải toả lo âu</p>
            </div>
            <div class="columns-md-2 columns-xs-6 itemUudai">
               <img alt="Tư vấn &amp; Hỗ trợ" class="img-fluid lazyload" width="60px" height="60px" src="assets/images/vanchuyen/customer-service-2.png.webp" style="display: inline;">
               <div class="name">Tư vấn &amp; Hỗ trợ</div>
               <p *ngIf="settings">Hotline: {{ settings.hotline }}</p>
            </div>
            <div class="columns-md-2 columns-xs-6 itemUudai">
               <img alt="Giao hàng nhanh chóng" class="img-fluid lazyload" width="60px" height="60px" src="assets/images/vanchuyen/fast-delivery.png.webp" style="display: inline;">
               <div class="name">Giao hàng nhanh chóng</div>
               <p>Giao hàng siêu tốc trong 24h</p>
            </div>
            <div class="columns-md-2 columns-xs-6 itemUudai">
               <img alt="Thanh toán linh hoạt" class="img-fluid lazyload" width="60px" height="60px" src="assets/images/vanchuyen/credit-cards.png.webp" style="display: inline;">
               <div class="name">Thanh toán linh hoạt</div>
               <p>Chuyển khoản hoặc ship COD</p>
            </div>
            <div class="columns-md-2 columns-xs-6 itemUudai">
               <img alt="Tích lũy điểm" class="img-fluid lazyload" width="60px" height="60px" src="assets/images/vanchuyen/point.png.webp" style="display: inline;">
               <div class="name">Tích lũy điểm</div>
               <p>Tích điểm nhận quà</p>
            </div>
         </div>
      </div>
   </div>
   <div [innerHTML]="schema"></div>
  </main>
  