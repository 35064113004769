// custom-url-matchers.ts

import { UrlSegment, UrlMatchResult } from '@angular/router';

// Custom URL Matcher cho thương hiệu: domain/slugCategorie/slugBrand
export function brandUrlMatcher(url: UrlSegment[]): UrlMatchResult | null {
  if (url.length === 2) {
    return {
      consumed: url,
      posParams: {
        slugCategorie: url[0],
        slugBrand: url[1]
      }
    };
  }
  return null;
}

// Custom URL Matcher cho danh mục sản phẩm: domain/slugCategorie-cIdCategorie
export function categoryUrlMatcher(url: UrlSegment[]): UrlMatchResult | null {
  if (url.length === 1) {
    const regex = /^([a-zA-Z0-9-]+)-c(\d+)$/;
    const match = url[0].path.match(regex);
    if (match) {
      return {
        consumed: url,
        posParams: {
          slugCategorie: new UrlSegment(match[1], {}),
          idCategorie: new UrlSegment(match[2], {})
        }
      };
    }
  }
  return null;
}

// Custom URL Matcher cho danh mục tin tức: domain/slugDanhmuc
export function newsCategoryUrlMatcher(url: UrlSegment[]): UrlMatchResult | null {
  if (url.length === 1) {
    return {
      consumed: url,
      posParams: {
        slugDanhmuc: url[0]
      }
    };
  }
  return null;
}

// Custom URL Matcher cho chi tiết tin tức: domain/slugDanhmuc/slugTintuc-pIdTintuc
// export function newsDetailUrlMatcher(url: UrlSegment[]): UrlMatchResult | null {
//   if (url.length === 2) {
//     const regex = /^([a-zA-Z0-9-]+)-p(\d+)$/;
//     const match = url[1].path.match(regex);
//     if (match) {
//       return {
//         consumed: url,
//         posParams: {
//           slugDanhmuc: url[0],
//           slugTintuc: new UrlSegment(match[1], {}),
//           idTintuc: new UrlSegment(match[2], {})
//         }
//       };
//     }
//   }
//   return null;
// }
export function newsDetailUrlMatcher(url: UrlSegment[]): UrlMatchResult | null {
  // Kiểm tra nếu có ít nhất 2 segment và segment cuối kết thúc bằng ".html"
  if (url.length === 2 && url[1].path.endsWith('.html')) {
    const lastSegment = url[1].path;

    // Loại bỏ đuôi ".html" và kiểm tra xem có "-p<id>" ở cuối segment hay không
    const regex = /^([a-zA-Z0-9-]+)-p(\d+)\.html$/;
    const match = lastSegment.match(regex);

    if (match) {
      // Nếu URL khớp, phân tách slug của tin tức và id
      const slugNew = match[1];  // Phần slug của tin tức
      const id = match[2];       // Phần id của tin tức

      return {
        consumed: url,
        posParams: {
          slugNewCategory: url[0],  // Danh mục tin tức (segment đầu tiên)
          slugNew: new UrlSegment(slugNew, {}),  // Slug của tin tức (không có "-p")
          idTintuc: new UrlSegment(id, {})  // ID của tin tức
        }
      };
    }
  }
  return null;
}