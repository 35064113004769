import { Component } from '@angular/core';
import { AuthService } from '../service/auth.service';
import { CommonModule } from '@angular/common';
import { Router, RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-profile',
  standalone: true,
  imports: [RouterModule, CommonModule, FormsModule],
  templateUrl: './profile.component.html',
  styleUrl: './profile.component.css'
})
export class ProfileComponent {
  user: any = null;
  token: string = '';
  name:string="";
  surName: string ="";
  email: string ="";
  phone: string ="";
  address: string ="";
  isLoading: boolean = false;

  constructor(
    private authService: AuthService,
    private toastr: ToastrService,
    public router: Router
  ) {}

  ngOnInit(): void {
    this.user = this.authService.user;
    this.token = this.authService.token;

    if(!this.token && !this.user){
      setTimeout(() => {
        this.router.navigateByUrl("/login");
      }, 500);
      return;
    }

    this.email=this.user.email;
    this.name=this.user.name;
    this.surName=this.user.surName;
    this.phone=this.user.phone;
    this.address=this.user.address;
  }

  saveUser(){
    this.isLoading = true;
    if(!this.name || !this.surName || !this.email || !this.phone || !this.address){
      this.toastr.error("Lỗi","Vui lòng nhập đầy đủ thông tin");
      this.isLoading = false;
      return;
    }
    let data={
      name : this.name,
      email : this.email,
      surName: this.surName,
      phone: this.phone,
      address: this.address
    }

    this.authService.updateInfo(data).subscribe((resp: any) =>{
      if(resp.error && resp.error.error=="Unauthorized"){
        this.toastr.error("Lỗi", resp.error.error);this.isLoading = false;
        return;
      }
      if(resp.error){
        this.toastr.error("Lỗi", resp.error);this.isLoading = false;
        return;
      }
      this.toastr.success("Thông báo", "Cập nhật thông tin tài khoản thành công.");
      this.isLoading = false;
    }, (error)=>{
      console.log(error);
      this.toastr.error("Lỗi", error.error.message);this.isLoading = false;
      return;
    })
  }
}
