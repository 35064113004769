import { Component} from '@angular/core';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { NewsService } from '../service/news.service';
import { CommonModule } from '@angular/common';
import { NgbPaginationModule } from '@ng-bootstrap/ng-bootstrap';
import { SettingsService } from '../../../services/settings.service';
import { LeftProductComponent } from '../../product/layout/left-product/left-product.component';

@Component({
  selector: 'app-list-news',
  standalone: true,
  imports: [RouterModule, CommonModule, NgbPaginationModule,LeftProductComponent],
  templateUrl: './list-news.component.html',
  styleUrl: './list-news.component.css'
})
export class ListNewsComponent {
  title:string='';
  type:string='';
  com:string='';
  news:any=[];
  settings:any=[];
  totalPages:number = 0;
  currentPage:number = 1;
  constructor(
    private activedRoute: ActivatedRoute,
    private router: Router,
    private newsService: NewsService,
    private settingsService: SettingsService,
  ){  }
  ngOnInit(): void {
    this.activedRoute.data.subscribe(data => {
      this.type = data['type'];
      this.title = data['title'];
      this.com = data['com'];
      this.settingsService.settings$.subscribe((settings:any) => {
        this.settingsService.updateMetaTags(this.title, settings.description, settings.logo, settings.title);
      });
    });
    this.activedRoute.queryParams.subscribe(params => {
      this.currentPage = params['page'] ? +params['page'] : 1;
      this.listNews(this.currentPage); // Tải dữ liệu cho trang hiện tại
    });
  }
  listNews(page=1){
    this.newsService.getNews(page,this.type).subscribe((resp:any)=>{
      this.news=resp.news;
      this.totalPages=resp.total;
      this.currentPage=page;
    })
  }
  loadPage(page: number): void {
    this.currentPage = page;
  
    // Cập nhật URL với tham số page
    this.router.navigate([], {
      relativeTo: this.activedRoute,
      queryParams: { page: this.currentPage },
      queryParamsHandling: 'merge' // Giữ lại các query params khác nếu có
    });
  
    // Thực hiện tải dữ liệu hoặc các hành động khác cho trang mới
    this.listNews(page);
  }
}
