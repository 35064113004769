@if(!isLoadingMail){
   <main>

      <!-- breadcrumb area start -->
      <section class="breadcrumb__area include-bg text-center pt-5 pb-5">
         <div class="container">
            <div class="row">
               <div class="col-xxl-12">
                  <div class="breadcrumb__content position-relative z-index-1">
                     <h3 class="breadcrumb__title">Quên mật khẩu</h3>
                     <div class="breadcrumb__list">
                        <span><a routerLink="/">Trang chủ</a></span>
                        <span>Đặt lại mật khẩu</span>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </section>
      <!-- breadcrumb area end -->
  
      <!-- login area start -->
      <section class="tp-login-area pb-140 position-relative z-index-1 fix">
         <div class="tp-login-shape">
            <img class="tp-login-shape-1" src="assets/img/login/login-shape-1.png" alt="">
            <img class="tp-login-shape-2" src="assets/img/login/login-shape-2.png" alt="">
            <img class="tp-login-shape-3" src="assets/img/login/login-shape-3.png" alt="">
            <img class="tp-login-shape-4" src="assets/img/login/login-shape-4.png" alt="">
         </div>
         <div class="container">
            <div class="row justify-content-center">
               <div class="col-xl-6 col-lg-8">
                  <div class="tp-login-wrapper">
                     <div class="tp-login-top text-center mb-4">
                        <h3 class="tp-login-title">Đặt lại mật khẩu</h3>
                        <p>Nhập địa chỉ email của bạn để yêu cầu đặt lại mật khẩu.</p>
                     </div>
                     <div class="tp-login-option">
                        <div class="tp-login-input-wrapper">
                           <div class="tp-login-input-box">
                              <div class="tp-login-input">
                                 <input id="email" class="form-control" [(ngModel)]="email" type="email" placeholder="shofy@mail.com">
                              </div>
                              <div class="tp-login-input-title">
                                 <label for="email">Email</label>
                              </div>
                           </div>
                        </div>
                        <div class="tp-login-bottom mb-4">
                           <button type="button" (click)="verifiedMail()" class="btn tp-login-btn w-100">Gửi Email</button>
                        </div>
                        <div class="tp-login-suggetions d-sm-flex align-items-center justify-content-center">
                           <div class="tp-login-forgot">
                              <span>Đã nhớ mật khẩu? <a routerLink="/login"> Đăng nhập</a></span>
                              
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </section>
      <!-- login area end -->
  
   </main>
}

 @defer (when isLoadingMail && !isLoadingCode){
   @if(isLoadingMail && !isLoadingCode){
      <app-code-forgot-password [emailCode]="email" (loadingCodeStatus)="loadingCode($event)"></app-code-forgot-password>
   }
 }
 @defer (when (isLoadingMail && isLoadingCode)){
   @if(isLoadingMail && isLoadingCode){
      <app-new-password [emailCode]="email"></app-new-password>
   }
   
 }