import { CommonModule, isPlatformBrowser } from '@angular/common';
import { ChangeDetectorRef, Component, Inject, PLATFORM_ID } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { NgbPaginationModule } from '@ng-bootstrap/ng-bootstrap';
import { LeftProductComponent } from '../layout/left-product/left-product.component';
import { ProductService } from '../service/product.service';
import { ToastrService } from 'ngx-toastr';
import { Title, Meta } from '@angular/platform-browser';
import { URL_WEBSITE } from '../../../config/config';
import { SettingsService } from '../../../services/settings.service';
import { ImagesLazyloadModule } from '../../../shared/images-lazyload/images-lazyload.module';
import { BrandService } from '../../brand/service/brand.service';

@Component({
  selector: 'app-categories',
  standalone: true,
  imports: [CommonModule, FormsModule,NgbPaginationModule, RouterModule, LeftProductComponent, ImagesLazyloadModule ],
  templateUrl: './categories.component.html',
  styleUrl: './categories.component.css'
})
export class CategoriesComponent {
  settings: any = [];
  products: any[] = [];
  totalPages:number = 0;
  currentPage:number = 1;
  prices: any[] = [];
  brands: any[] = [];

  minValue: number = 50;
  maxValue: number = 80;
  maxLimit:number= 1000000;
  minLimit:number= 0;
  priceFilter:string='';

  sliderKey!: string;
  categorie_slug:string='';
  categorie:any=[];
  categorie_id:string='';
  isContentExpanded: boolean = false;
  isShowFilter: boolean = false;

  filterBrand:any={};
  filterPrice:any={};

  
  constructor(
    private productService: ProductService,
    private brandService: BrandService,
    private cdr: ChangeDetectorRef,
    public activedRoute: ActivatedRoute,
    private router: Router,
    private toastr: ToastrService,
    @Inject(PLATFORM_ID) private platformId: Object,
    private titleService: Title,
    private metaService: Meta,
    private settingsService: SettingsService,
  ){}

  ngAfterViewInit() {
    // if (isPlatformBrowser(this.platformId)) {
    // this.initSlider();
    // }
    this.cdr.detectChanges();
  }
  ngOnInit(): void {
    this.activedRoute.queryParams.subscribe(queryParams => {
      this.priceFilter = queryParams['p'];
      this.currentPage = queryParams['page'] ? +queryParams['page'] : 1;
      if(this.priceFilter) this.getProducts(this.currentPage);
    });
    this.activedRoute.params.subscribe((resp:any) => {
      this.categorie_slug=resp.slugCategorie;
      this.categorie_id=resp.idCategorie;

      this.productService.getCategoriesDetail(this.categorie_slug).subscribe((resp:any) => {
        this.categorie=resp.categorie;
        this.categorie_id=resp.categorie.id;
        this.getProducts(this.currentPage);
      })
    })
    //Lấy danh sách giá search
    this.productService.getPriceSearchs().subscribe((resp:any) =>{
      this.prices=resp.prices;
      this.brands=resp.brands;
    })
    // this.brandService.getAllBrand(this.currentPage).subscribe((resp:any) =>{
    //   this.brands=resp.brands;
    // })
    // Lấy giá cao nhất của toàn bộ sản phẩm
    // this.productService.getMaxPrice().subscribe((maxPriceData: any) => {
    //   this.maxLimit = maxPriceData.maxPrice; // maxPrice từ API
    //   this.maxValue = maxPriceData.maxPrice/2;
    //   this.minValue = maxPriceData.minPrice;
    // });
  }
  showMore() {
    this.isContentExpanded = !this.isContentExpanded; // Đảo trạng thái
  }
  onPriceRangeChange(event: any): void {
    this.filtterProducts();
  }

  onPageChange(page: number): void {
    this.currentPage = page;
    this.getProducts(this.currentPage);
  }

  getProducts(page=1): void {
    this.productService.getProductsCategorie(page, this.categorie.id, this.categorie.type_categorie, this.priceFilter,'').subscribe((data: any) => {
      this.products = data.products;
      this.totalPages = data.total;
    });
  }
  filtterProducts(page=1): void {
    this.productService.filtterProductsCategorie(this.minValue, this.maxValue, page, this.categorie.id, this.categorie.type_categorie).subscribe((data: any) => {
      this.products = data.products;
      this.totalPages = data.total;
    });
  }
  loadPage(page: number): void {
    this.currentPage = page;
  
    // Cập nhật URL với tham số page
    this.router.navigate([], {
      relativeTo: this.activedRoute,
      queryParams: { page: this.currentPage },
      queryParamsHandling: 'merge' // Giữ lại các query params khác nếu có
    });
  
    // Thực hiện tải dữ liệu hoặc các hành động khác cho trang mới
    this.getProducts(page);
  }


  // initSlider() {
  //   this.sliderKey = this.generateUniqueId();
  //   setTimeout(() => {
  //     this.fillColor(this.minValue, this.maxValue, this.sliderKey);
  //   }, 200);
  // }

  generateUniqueId(): string {
    const dateString = Date.now().toString(36);
    const randomness = Math.random().toString(36).substr(2);
    return dateString + randomness;
  }

  valueChanged(sliderKey: string) {
    //console.log('KEy', sliderKey);
    // this.sliderValueChanged.emit({
    //   min: this.minValue,
    //   max: this.maxValue,
    // });
    this.fillColor(this.minValue, this.maxValue, sliderKey);
  }

  fillColor(minInput: number, maxInput: number, id: string) {
    let minInputValue: number;
    let maxInputValue: number;

    let totalLimit = Math.abs(this.minLimit) + Math.abs(this.maxLimit);

    if (minInput < 0) {
      minInputValue = Math.abs(this.minLimit) - Math.abs(minInput);
    } else {
      minInputValue = Math.abs(this.minLimit) + minInput;
    }

    if (maxInput >= 0) {
      maxInputValue = Math.abs(this.minLimit) + maxInput;
    } else {
      maxInputValue = Math.abs(this.minLimit) - Math.abs(maxInput);
    }
    if (id !== null || id !== undefined) {
      let percent1 = (minInputValue / totalLimit) * 100;
      let percent2 = (maxInputValue / totalLimit) * 100;
      let sliderTrack: any = document.querySelector('#slider-' + id);
      sliderTrack.style.background = `linear-gradient(to right, #dadae5 ${percent1}% , #3264fe ${percent1}% , #3264fe ${percent2}%, #dadae5 ${percent2}%)`;
    }
  }
  onImageError(event: any): void {
    event.target.src = 'assets/images/no-image.jpg'; // Đặt ảnh thay thế khi ảnh bị lỗi
  }
  showFilter(){
    this.isShowFilter = !this.isShowFilter;
  }
  closeFilter(){
    this.isShowFilter = false;
  }
}
