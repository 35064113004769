import { CommonModule, isPlatformBrowser } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Inject, Input, Output, PLATFORM_ID } from '@angular/core';
import { RouterModule } from '@angular/router';
import { HomeService } from '../../pages/home/service/home.service';

declare var $:any;
declare function InitNav([]):any;
@Component({
  selector: 'app-nav',
  standalone: true,
  imports: [RouterModule, CommonModule],
  templateUrl: './nav.component.html',
  styleUrl: './nav.component.css',
  //changeDetection: ChangeDetectionStrategy.OnPush // Sử dụng OnPush
})
export class NavComponent {
  @Input() openNav: boolean = false;
  @Output() closeNav = new EventEmitter<void>();
  openMenus: { [key: string]: boolean } = {};
  
  categories: any=[];
  abouts: any=[];
  services: any=[];
  giaiphaps: any=[];
  tintucs: any=[];
  constructor(
    private homeService: HomeService,
    @Inject(PLATFORM_ID) private platformId: Object,
  ){}
  ngOnInit(): void {
    //Called after the constructor, initializing input properties, and the first call to ngOnChanges.
    //Add 'implements OnInit' to the class.
    this.homeService.menus().subscribe((resp:any)=>{
      this.categories=resp.categories;
      this.abouts=resp.aboutUs;
      this.services=resp.serviceCategorie;
      this.giaiphaps=resp.giaiphapCategorie;
      this.tintucs=resp.newCategorie;
    })
    if (isPlatformBrowser(this.platformId)) {
      setTimeout(()=>{
        InitNav($);
      },50)
    }
  }
  closeMenu() {
    this.closeNav.emit();
  }

  toggleDropdown(menu: string) {
    this.openMenus[menu] = !this.openMenus[menu];
  }

  // Check if a dropdown is open
  isOpen(menu: string): boolean {
    return !!this.openMenus[menu];
  }

  handleHover(id: number): void {
    const elements = document.querySelectorAll('.subcategorie');
    elements.forEach(el => (el as HTMLElement).style.display = 'none');

    const targetElement = document.querySelector(`.subcategorie_${id}`);
    if (targetElement) {
      (targetElement as HTMLElement).style.display = 'block';
    }
  }
}
