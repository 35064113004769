import { Component } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { AuthService } from '../service/auth.service';
import { Router, RouterModule } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-register',
  standalone: true,
  imports: [FormsModule, RouterModule],
  templateUrl: './register.component.html',
  styleUrl: './register.component.css'
})
export class RegisterComponent {
  name:string="";
  surname: string ="";
  email: string ="";
  phone: string ="";
  password: string ="";

  constructor(
    private authService:AuthService,
    private router:Router,
    private toastr: ToastrService
  ){}
  register(){
    if(!this.name || !this.surname || !this.email || !this.phone || !this.password){
      this.toastr.error("Lỗi đăng ký","Vui lòng nhập thông tin đăng ký tài khoản");
    }
    let data={
      name : this.name,
      email : this.email,
      surname: this.surname,
      phone: this.phone,
      password: this.password,
      password_confirmation: this.password
    }

    this.authService.register(data).subscribe((resp: any) =>{
      console.log(resp);
      if(resp.error && resp.error.error=="Unauthorized"){
        this.toastr.error("Lỗi", resp.error.error);
        return;
      }
      if(resp.error){
        this.toastr.error("Lỗi", resp.error);
        return;
      }
      this.toastr.success("Thông báo", "Đăng ký tài khoản thành công. Vui lòng kiểm tra hộp thư xác nhận tài khoản");
      setTimeout(() => {
        this.router.navigateByUrl("/login");
      }, 500);
      
    }, (error)=>{
      console.log(error);
      this.toastr.error("Lỗi", error.error.message);
      return;
    })
  }
}
