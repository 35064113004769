import { CommonModule, isPlatformBrowser } from '@angular/common';
import { Component, Inject, PLATFORM_ID } from '@angular/core';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { SettingsService } from '../../services/settings.service';
import { Title, Meta, DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { URL_WEBSITE } from '../../config/config';
import { ToastrService } from 'ngx-toastr';
import { HomeService } from '../home/service/home.service';

@Component({
  selector: 'app-contact',
  standalone: true,
  imports: [CommonModule, FormsModule, RouterModule, ReactiveFormsModule],
  templateUrl: './contact.component.html',
  styleUrl: './contact.component.css'
})
export class ContactComponent {
  contactForm: FormGroup;
  settings: any = null;
  title:string="Liên hệ";
  isLoading: boolean = false;
  sanitizedIframe!: SafeHtml;
  constructor(
    private settingsService: SettingsService,
    @Inject(PLATFORM_ID) private platformId: Object,
    private titleService: Title,
    private metaService: Meta,
    private fb: FormBuilder,
    private toastr: ToastrService,
    private homeService: HomeService,
    private sanitizer: DomSanitizer
  ){
    this.contactForm = this.fb.group({
      name: ['', Validators.required],
      phone: ['', [Validators.required, Validators.pattern('^[0-9]{10,11}$')]],
      email: ['', [Validators.required, Validators.email]],
      subject: [''],
      message: [''],
    });
  }
  ngOnInit(): void {
    //Called after the constructor, initializing input properties, and the first call to ngOnChanges.
    //Add 'implements OnInit' to the class.
    this.settingsService.settings$.subscribe(
      (data) => {
        this.settings = data;
        this.updateMetaTags();

        const iframeData = data.map;

        // Sử dụng DomSanitizer để làm sạch và bảo mật nội dung iframe
        this.sanitizedIframe = this.sanitizer.bypassSecurityTrustHtml(iframeData); 
      },
      (error) => {
        console.error('Error fetching settings:', error);
      }
    );

    
  }
  private updateMetaTags(): void {
    if (isPlatformBrowser(this.platformId)) {
      const title = this.title || this.settings.title;
      const description = this.settings.description;
      const imageUrl = this.settings.photo;

      this.titleService.setTitle(title+ " - "+URL_WEBSITE);
      this.metaService.updateTag({ name: 'description', content: description });
      this.metaService.updateTag({ property: 'og:title', content: title });
      this.metaService.updateTag({ property: 'og:description', content: description });
      this.metaService.updateTag({ property: 'og:url', content: window.location.href });
      this.metaService.updateTag({ property: 'og:image', content: imageUrl });
      this.metaService.updateTag({ name: 'keywords', content:this.settings.keywords });
    }
  }
  send(){
    this.isLoading = true;
    if (this.contactForm.invalid) {
      this.toastr.error('Vui lòng kiểm tra lại thông tin');
      this.isLoading = false;
      return;
    }

    const orderData = {
      ...this.contactForm.value,
    };
    this.homeService.sendContact(orderData).subscribe((resp:any) =>{
      this.toastr.success('Gửi liên hệ thành công! Chúng tôi sẽ liên hệ bạn sớm nhất');
    },(error:any) =>{
      this.isLoading = false;
      this.toastr.error('Gửi liên lệ thất bại. Vui lòng thử lại.');
    })
  }
}
