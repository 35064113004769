<main class="bg-gray-100 py-3">

    <!-- breadcrumb area start -->
    <section class="breadcrumb__area include-bg py-5" data-bg-color="#EFF1F5">
       <div class="container">
          <div class="row">
             <div class="col-xxl-12">
                <div class="breadcrumb__content p-relative z-index-1">
                   <h3 class="breadcrumb__title">Thanh toán</h3>
                   <div class="breadcrumb__list">
                      <span><a routerLink="/">Trang chủ</a></span>
                      <span>Thanh toán</span>
                   </div>
                </div>
             </div>
          </div>
       </div>
    </section>
    <!-- breadcrumb area end -->

    <!-- checkout area start -->
    <section class="tp-checkout-area pb-5" data-bg-color="#EFF1F5">
       <div class="container">
         <form [formGroup]="checkoutForm" (ngSubmit)="onSubmit()">
          <div class="row">
             <div class="col-lg-7">
                <div class="tp-checkout-bill-area">
                   <h3 class="tp-checkout-bill-title">Thông tin thanh toán</h3>

                   <div class="tp-checkout-bill-form">
                         <div class="tp-checkout-bill-inner">
                            <div class="row">
                               <div class="col-md-12">
                                  <div class="tp-checkout-input">
                                     <label>Họ tên <span>*</span></label>
                                     <input type="text" placeholder="họ và tên" id="frmName" formControlName="hoten" [value]="user?.name || ''">
                                  </div>
                               </div>
                               <div class="col-md-12">
                                  <div class="tp-checkout-input">
                                     <label>Địa chỉ</label>
                                     <input type="text" placeholder="Địa chỉ nhận hàng" id="frmAddress" formControlName="address" [value]="user?.address || ''">
                                  </div>
                               </div>
                               <div class="col-md-12">
                                  <div class="tp-checkout-input">
                                     <label>Số điện thoại <span>*</span></label>
                                     <input type="text" placeholder="" id="frmPhone" formControlName="phone" [value]="user?.phone || ''">
                                  </div>
                               </div>
                               <div class="col-md-12">
                                  <div class="tp-checkout-input">
                                     <label>Email <span>*</span></label>
                                     <input type="email" placeholder="" id="frmEmail" formControlName="email" [value]="user?.email || ''">
                                  </div>
                               </div>
                               <div class="col-md-12">
                                  <div class="tp-checkout-input">
                                     <label>Ghi chú với chúng tôi</label>
                                     <textarea placeholder="" id="frmContent" formControlName="noted"></textarea>
                                  </div>
                                  <input type="hidden" formControlName="userId" [value]="user?.id || ''">
                               </div>
                            </div>
                         </div>
                   </div>
                </div>
             </div>
             <div class="col-lg-5">
                <!-- checkout place order -->
                <div class="tp-checkout-place white-bg">
                   <h3 class="tp-checkout-place-title">Đơn hàng của bạn</h3>

                   <div class="tp-order-info-list">
                      <ul>

                         <!-- header -->
                         <li class="tp-order-info-list-header">
                            <h4>Sản phẩm</h4>
                            <h4>Tổng tiền</h4>
                         </li>

                         <!-- item list -->
                         <li class="tp-order-info-list-desc" *ngFor="let item of cartItems">
                            <p>{{ item.name }}
                                <ng-container *ngIf="item.variation">
                                    <ng-container *ngFor="let variation of item.variation.properties"><b> - {{ variation.attribute.name }}</b>: {{ variation.name }} </ng-container> 
                                </ng-container>
                            <span> x {{ item.quantity }}</span></p>
                            <span>{{ item.price | number:'1.0-0' }} đ</span>
                         </li>
                         <!-- total -->
                         <li class="tp-order-info-list-total">
                            <span>Tổng đơn hàng</span>
                            <span>{{ getTotalPrice() | number:'1.0-0' }} đ</span>
                         </li>
                      </ul>
                   </div>
                   <div class="tp-checkout-payment">
                     <div class="tp-checkout-payment-item">
                       <input type="radio" id="cod" name="payment" value="cod" formControlName="payment" [checked]="checkoutForm.get('payment')?.value === 'cod'">
                       <label for="cod">Thanh toán khi nhận hàng</label>
                     </div>
                     <div class="tp-checkout-payment-item">
                       <input type="radio" id="bank_transfer" name="payment" value="bank_transfer" formControlName="payment" [checked]="checkoutForm.get('payment')?.value === 'bank_transfer'">
                       <label for="bank_transfer">Thanh toán chuyển khoản</label>
                     </div>
                   </div>
                   <div class="tp-checkout-agree">
                      <div class="tp-checkout-option">
                         <input id="read_all" type="checkbox" formControlName="agree">
                         <label for="read_all">Tôi đã đọc và đồng ý với điều khoản & điều kiện của trang web.</label>
                      </div>
                   </div>
                   <div class="tp-checkout-btn-wrapper">
                        <div *ngIf="isLoading" class="loading-indicator">
                           Đang xử lý, vui lòng chờ...
                        </div>
                        <button class="btn tp-checkout-btn w-100" [disabled]="isLoading">Thanh toán</button>
                   </div>
                </div>
             </div>
          </div>
         </form>
       </div>
    </section>
    <!-- checkout area end -->


 </main>