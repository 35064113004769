import { Component, Inject, PLATFORM_ID } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { HomeService } from '../home/service/home.service';
import { CommonModule, isPlatformBrowser } from '@angular/common';
import { Title, Meta } from '@angular/platform-browser';
import { URL_WEBSITE } from '../../config/config';
import { SettingsService } from '../../services/settings.service';

@Component({
  selector: 'app-content-article',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './content-article.component.html',
  styleUrl: './content-article.component.css'
})
export class ContentArticleComponent {
  title:string='Giới thiệu';
  article:any=[];
  type:string='';
  com:string='';
  news_id:string='';
  totalPages:number = 0;
  currentPage:number = 1;
  settings:any=[];
  constructor(
    private homeService: HomeService,
    public activedRoute: ActivatedRoute,
    @Inject(PLATFORM_ID) private platformId: Object,
    private titleService: Title,
    private metaService: Meta,
    private settingsService: SettingsService,
  ){}
  ngOnInit(): void {
    this.activedRoute.data.subscribe(data => {
      this.type = data['type'];
      this.title = data['title'];
      this.com = data['com'];
      this.settingsService.settings$.subscribe(
        (data) => {
          this.settings = data;
          this.detailBlogs();
        },
        (error) => {
          console.error('Error fetching settings:', error);
        }
      );
    });
  }

  detailBlogs(){
    this.homeService.getArticleDetail(this.type).subscribe((resp:any)=>{
      this.article=resp.article;
      this.updateMetaTags();
    })
  }
  private updateMetaTags(): void {
    if (isPlatformBrowser(this.platformId)) {
      const title = this.article.title || this.settings.title;
      const description = this.article.description || this.settings.description;
      const imageUrl = this.article.photo || this.settings.photo;

      this.titleService.setTitle(title+ " - "+URL_WEBSITE);
      this.metaService.updateTag({ name: 'description', content: description });
      this.metaService.updateTag({ property: 'og:title', content: title });
      this.metaService.updateTag({ property: 'og:description', content: description });
      this.metaService.updateTag({ property: 'og:url', content: window.location.href });
      this.metaService.updateTag({ property: 'og:image', content: imageUrl });
      this.metaService.updateTag({ name: 'keywords', content: this.article.keywords || this.settings.keywords });
    }
  }
}
