<main class="bg-gray-100 py-3">
    <div class="container py-5">
        <div class="row">
            <div class="col-md-3 col-12">
               <div class="tp-shop-widget mb-5  p-3 bg-white">
                  <h3 class="tp-shop-widget-title">Thương hiệu</h3>
                  <div class="tp-shop-widget-content ">
                     <ul>
                        <li *ngFor="let brand of brands">
                           <a routerLink="/{{ categorie.slug }}/{{ brand.slug }}">
                             {{ brand.name }}
                           </a>
                         </li>
                     </ul>
                  </div>
               </div>
               <div class="tp-shop-widget mb-5  p-3 bg-white">
                  <h3 class="tp-shop-widget-title">Tìm kiếm theo giá</h3>
                  <div class="tp-shop-widget-content ">
                     <ul>
                        <li *ngFor="let item of prices">
                           <a [routerLink]="['/', categorie.slug + '-c' + categorie.id]" [queryParams]="{ p: item.slug }">
                             {{ item.name }}
                           </a>
                         </li>
                     </ul>
                  </div>
               </div>
                 <app-left-product></app-left-product>
            </div>
            <div class="col-md-9 col-12">
               <div class="bg-white p-3">
                  <section class="breadcrumb__area include-bg mb-3">
                     <div class="breadcrumb__content position-relative z-index-1">
                        <div class="breadcrumb__list mb-3">
                           <span><a routerLink="/">Trang chủ</a></span>
                           <span>{{categorie.name}}</span>
                        </div>
                        <h1 class="breadcrumb__title">{{categorie.name}}</h1>
                     </div>
                 </section>
                  <div class="grid_fillter mb-3">
                     <div class="d-flex justify-content-between">
                        <div class="col-md-6 col-xs-12">
                           <div class="filter-total ">
                              <div class="filter-item__title jsTitle" (click)="showFilter()">
                                 <div class="arrow-filter" [ngClass]="{'active': isShowFilter==true}"></div>
                                 <i class="fas fa-filter"></i> Lọc <strong class="number count-total"></strong>
                              </div>
                              <div class="filter-show show-total" id="wrapper" [ngClass]="{'active': isShowFilter==true}">
                                 <div class="list-filter-active">
                                    <span>Đã chọn: </span>
                                    <div class="manu"></div>
                                    <div class="price"></div>
                                    <a routerLink="/{{categorie.slug}}" class="clr-filter" >Xóa tất cả</a>
                                 </div>
                                 <div class="show-total-main">
                                    <a href="javascript:;" class="close-popup-total" (click)="closeFilter()">
                                       <i class="far fa-times-circle me-1"></i> Đóng
                                    </a>
                                    <div class="show-total-item clearfix warpper-manu-inside arranged">
                                       <p class="show-total-txt">Hãng</p>
                                       <div class="filter-list filter-list--hang manu d-flex flex-wrap align-items-center">
                                          <a class="c-btnbox filter-manu" data-href="samsung" data-id="{{brand.id}}" data-name="{{brand.name}}"  *ngFor="let brand of brands">
                                             <img *ngIf="brand.photo; else usePhoto" appLazyLoad="{{ brand.photo }}" src="assets/images/400x400.png" alt="{{brand.name}}" class="img-fluid" loading="lazy" (error)="onImageError($event)" width="68" height="30">
                                             <!-- Nếu không có thumbnail thì hiển thị ảnh photo -->
                                             <ng-template #usePhoto>
                                                   <img src="assets/images/no-image.jpg" alt="{{brand.name}}" class="img-fluid" width="68" height="30">
                                             </ng-template>
                                          </a>
                                       </div>
                                    </div>
                                    <div class="filter-border"></div>
                                    <div class="show-total-item count-item arranged">
                                       <p class="show-total-txt">Giá</p>
                                       <div class="filter-list price d-flex flex-wrap align-items-center">
                                          <ng-container  *ngFor="let item of prices">
                                             <a class="c-btnbox " data-id="{{item.id}}">{{item.name}} </a>
                                          </ng-container>
                                       </div>
                                    </div>
                                    
                                    <div class="filter-border"></div>
                                    <div class="filter-button filter-button--total" style="display: block;">
                                       <a href="/dtdd" class="btn-filter-close">Bỏ chọn</a>
                                       <a href="javascript:;" class="btn-filter-readmore">Xem kết quả </a>
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
                  <div class="grid_container">
                     <div class="columns-md-3 columns-xs-6 mb-4 item_product" *ngFor="let product of products">
                        <div class="sale" *ngIf="product.sale">{{ product.sale }}%</div>
                        <div class="images">
                           <a routerLink="/san-pham/{{ product.slug }}-tjk{{ product.id }}">
                              <img *ngIf="product.image; else usePhoto" appLazyLoad="{{ product.image.thumbnail }}" src="assets/images/400x400.png" alt="{{product.name}}" class="img-fluid" loading="eager">
                              <!-- Nếu không có thumbnail thì hiển thị ảnh photo -->
                              <ng-template #usePhoto>
                              <img src="assets/images/no-image.jpg" alt="{{product.name}}" class="img-fluid">
                              </ng-template>
                           </a>
                        </div>
                        <div class="info p-4 py-2">
                           <div class="tp-product-category"><a routerLink="{{ product.categorie_first.slug }}-c{{ product.categorie_first.id }}">{{ product.categorie_first.name }}</a></div>
                           <a routerLink="/san-pham/{{ product.slug }}-tjk{{ product.id }}">
                              <h3>{{ product.name }}</h3>
                           </a>
                           <div class="tp-product-rating-icon">
                           <i class="fas fa-star"></i>
                           <i class="fas fa-star"></i>
                           <i class="fas fa-star"></i>
                           <i class="fas fa-star"></i>
                           <i class="fas fa-star"></i>
                           </div>
                           <div class="price">
                              <span *ngIf="product.price" class="me-3">{{ product.price | number:'1.0-0' }} đ</span>
                              <strong *ngIf="product.priceSale > 0">
                                 {{ product.priceSale | number:'1.0-0' }} đ
                              </strong>
                              <strong *ngIf="product.priceSale <= 0">
                                 Liên hệ
                              </strong>
                           </div>
                        </div>
                     </div>
                  </div>
                     <ngb-pagination *ngIf="totalPages/20>1"
                        [collectionSize]="totalPages"
                        [(page)]="currentPage"
                        [pageSize]="20"
                        [rotate]="true"
                        [boundaryLinks]="true"
                        (pageChange)="loadPage($event)"
                        [maxSize]="5"
                  ></ngb-pagination>
   
                  <div class="contentDetail mh-200 bg-white p-3 my-3" *ngIf="categorie.content" [ngStyle]="{'height': isContentExpanded ? 'auto' : '200px'}">
                     <div class="content" [innerHTML]="categorie.content"></div>
                     <div class="viewMore">
                         <a class="btn btn_readmore ripple-surface" (click)="showMore()" [class.active]="isContentExpanded"><span>{{ isContentExpanded ? 'Rút gọn' : 'Xem thêm' }}</span> <svg class="svg svg-more-down ml-3" xmlns="http://www.w3.org/2000/svg" width="9" height="6" viewBox="0 0 9 6"><path fill="none" fill-rule="evenodd" stroke="#08711c" stroke-linecap="round" stroke-width="2" d="M7.5 1.5l-3 3-3-3"></path></svg></a>
                     </div>
                 </div>
               </div>
            </div>
        </div>
    </div>
</main>