import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, Inject, PLATFORM_ID } from '@angular/core';
import { ProductService } from '../service/product.service';
import { CommonModule, isPlatformBrowser } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { NgbPaginationModule } from '@ng-bootstrap/ng-bootstrap';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { LeftProductComponent } from '../layout/left-product/left-product.component';
import { Title, Meta } from '@angular/platform-browser';
import { URL_WEBSITE } from '../../../config/config';
import { SettingsService } from '../../../services/settings.service';

@Component({
  selector: 'app-list-product',
  standalone: true,
  imports: [CommonModule, FormsModule,NgbPaginationModule, RouterModule, LeftProductComponent ],
  templateUrl: './list-product.component.html',
  styleUrl: './list-product.component.css'
})
export class ListProductComponent  implements AfterViewInit {
  title:string="Sản phẩm";
  settings:any=[];
  products: any[] = [];
  totalPages:number = 0;
  currentPage:number = 1;

  minValue: number = 50;
  maxValue: number = 80;
  maxLimit:number= 1000000;
  minLimit:number= 0;

  sliderKey!: string;

  
  constructor(
    private productService: ProductService,
    private cdr: ChangeDetectorRef,
    @Inject(PLATFORM_ID) private platformId: Object,
    private titleService: Title,
    private metaService: Meta,
    private settingsService: SettingsService,
    private router: Router,
    private route: ActivatedRoute
  ){}

  ngAfterViewInit() {
    if (isPlatformBrowser(this.platformId)) {this.initSlider();}
    this.cdr.detectChanges();
  }
  ngOnInit(): void {
    this.settingsService.settings$.subscribe(
      (data) => {
        this.settings = data;
        this.updateMetaTags();
      },
      (error) => {
        console.error('Error fetching settings:', error);
      }
    );
    // Lấy giá cao nhất của toàn bộ sản phẩm
    this.productService.getMaxPrice().subscribe((maxPriceData: any) => {
      this.maxLimit = maxPriceData.maxPrice; // maxPrice từ API
      this.maxValue = maxPriceData.maxPrice/2;
      this.minValue = maxPriceData.minPrice;
    });
    this.route.queryParams.subscribe(params => {
      this.currentPage = params['page'] ? +params['page'] : 1;
      this.getProducts(this.currentPage); // Tải dữ liệu cho trang hiện tại
    });
  }

  onPriceRangeChange(event: any): void {
    this.filtterProducts();
  }

  onPageChange(page: number): void {
    this.currentPage = page;
    this.getProducts();
  }

  getProducts(page=1): void {
    this.productService.getProducts(page).subscribe((data: any) => {
      this.products = data.products;
      this.totalPages = data.total;
    });
  }
  filtterProducts(page=1): void {
    this.productService.filtterProducts(this.minValue, this.maxValue, page).subscribe((data: any) => {
      this.products = data.products;
      this.totalPages = data.total;
    });
  }
  // loadPage($event:any){
  //   this.getProducts($event);
  // }
  loadPage(page: number): void {
    this.currentPage = page;
  
    // Cập nhật URL với tham số page
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: { page: this.currentPage },
      queryParamsHandling: 'merge' // Giữ lại các query params khác nếu có
    });
  
    // Thực hiện tải dữ liệu hoặc các hành động khác cho trang mới
    this.getProducts(page);
  }


  initSlider() {
    this.sliderKey = this.generateUniqueId();
    setTimeout(() => {
      this.fillColor(this.minValue, this.maxValue, this.sliderKey);
    }, 200);
  }

  generateUniqueId(): string {
    const dateString = Date.now().toString(36);
    const randomness = Math.random().toString(36).substr(2);
    return dateString + randomness;
  }

  valueChanged(sliderKey: string) {
    console.log('KEy', sliderKey);
    // this.sliderValueChanged.emit({
    //   min: this.minValue,
    //   max: this.maxValue,
    // });
    this.fillColor(this.minValue, this.maxValue, sliderKey);
  }

  fillColor(minInput: number, maxInput: number, id: string) {
    let minInputValue: number;
    let maxInputValue: number;

    let totalLimit = Math.abs(this.minLimit) + Math.abs(this.maxLimit);

    if (minInput < 0) {
      minInputValue = Math.abs(this.minLimit) - Math.abs(minInput);
    } else {
      minInputValue = Math.abs(this.minLimit) + minInput;
    }

    if (maxInput >= 0) {
      maxInputValue = Math.abs(this.minLimit) + maxInput;
    } else {
      maxInputValue = Math.abs(this.minLimit) - Math.abs(maxInput);
    }
    if (id !== null || id !== undefined) {
      let percent1 = (minInputValue / totalLimit) * 100;
      let percent2 = (maxInputValue / totalLimit) * 100;
      let sliderTrack: any = document.querySelector('#slider-' + id);
      sliderTrack.style.background = `linear-gradient(to right, #dadae5 ${percent1}% , #3264fe ${percent1}% , #3264fe ${percent2}%, #dadae5 ${percent2}%)`;
    }
  }

  private updateMetaTags(): void {
    if (isPlatformBrowser(this.platformId)) {
      const title = this.title || this.settings.title;
      const description = this.settings.description;
      const imageUrl = this.settings.photo;

      this.titleService.setTitle(title+ " - "+URL_WEBSITE);
      this.metaService.updateTag({ name: 'description', content: description });
      this.metaService.updateTag({ property: 'og:title', content: title });
      this.metaService.updateTag({ property: 'og:description', content: description });
      this.metaService.updateTag({ property: 'og:url', content: window.location.href });
      this.metaService.updateTag({ property: 'og:image', content: imageUrl });
      this.metaService.updateTag({ name: 'keywords', content:this.settings.keywords });
    }
  }
}
