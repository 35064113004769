import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { URL_SERVICEIO } from '../../../config/config';
import { Observable, tap } from 'rxjs';
import { SettingsService } from '../../../services/settings.service';

@Injectable({
  providedIn: 'root'
})
export class BrandService {
  private apiUrl = URL_SERVICEIO+'/frontEnd/products'; // Thay thế với URL thực tế của API
  private maxPriceUrl = URL_SERVICEIO+'/frontEnd/products/max-price'; // Endpoint mới cho giá cao nhất

  constructor(private http: HttpClient, private settingsService: SettingsService) { }
  getAllBrand(page:number){
    let URL= URL_SERVICEIO+"/frontEnd/brands?page="+page;
    return this.http.get(URL);
  }
  getBrandsDetail(id:string){
    let URL=URL_SERVICEIO+"/frontEnd/brands/"+id;
    return this.http.get(URL).pipe(
      tap((resp: any) => {
        this.settingsService.settings$.subscribe((settings:any) => {
          const title_custom = resp?.brand?.title ? resp.brand?.title : resp.brand?.name;
          // Kiểm tra description, nếu product.description rỗng thì lấy setting.description
          const description_custom = resp.brand?.description ? resp.brand?.description : settings?.description;
          // Kiểm tra image, nếu product.image rỗng thì lấy setting.photo
          const imageUrl = resp.brand?.photo ? resp.brand?.photo : settings?.logo;
          this.settingsService.updateMetaTags(title_custom, description_custom, imageUrl, settings.title);
        });
      })
    );
  }

  getProductsBrand(page: number, id:string): Observable<any> {
    //let URL=this.apiUrl+"?brand_id="+id+"&page="+page; console.log(URL);
    let params = new HttpParams()
    .set('brand_id', id)
    .set('page', page);

    return this.http.get<any>(this.apiUrl, { params });

    //return this.http.get(URL);
  }
  getMaxPrice(id:string): Observable<any> {
    let params = new HttpParams()
        .set('brand_id', id);
    return this.http.get<any>(this.maxPriceUrl);
  } 

  filtterProductsBrand(minPrice: number, maxPrice: number, page: number, id:string): Observable<any> {
    let params = new HttpParams()
      .set('minPrice', minPrice)
      .set('maxPrice', maxPrice)
      .set('brand_id', id)
      .set('page', page);

      return this.http.get<any>(this.apiUrl, { params });
  }
}