import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable, makeStateKey, PLATFORM_ID, TransferState } from '@angular/core';
import { Observable, of, tap } from 'rxjs';
import { URL_SERVICEIO, URL_WEBSITE } from '../../../config/config';
import { Title, Meta } from '@angular/platform-browser';
import { isPlatformBrowser } from '@angular/common';
import { SettingsService } from '../../../services/settings.service';

@Injectable({
  providedIn: 'root'
})
export class ProductService {
  private apiUrl = URL_SERVICEIO+'/frontEnd/products'; // Thay thế với URL thực tế của API
  private maxPriceUrl = URL_SERVICEIO+'/frontEnd/products/max-price'; // Endpoint mới cho giá cao nhất

  constructor(
    private http: HttpClient,private state: TransferState,
    @Inject(PLATFORM_ID) private platformId: Object,
    private settingsService: SettingsService
  ) { }

  getProducts(page: number): Observable<any> {
    let params = new HttpParams()
      .set('page', page);
    
    return this.http.get<any>(this.apiUrl, { params });
  }
  filtterProducts(minPrice: number, maxPrice: number, page: number): Observable<any> {
    let params = new HttpParams()
      .set('minPrice', minPrice)
      .set('maxPrice', maxPrice)
      .set('page', page);
    
    return this.http.get<any>(this.apiUrl, { params });
  }
  getPriceSearchs(){
    let URL = URL_SERVICEIO+'/frontEnd/products/prices';
    return this.http.get(URL);
  }
  getPriceSearchs_Brand(){
    let URL = URL_SERVICEIO+'/frontEnd/products/prices';
    return this.http.get(URL);
  }
  getProductsCategorie(page: number, id:string, type_categorie:number, price:string, brand:string): Observable<any> {
    
      if(type_categorie==1){
        let params = new HttpParams()
        .set('first_category_id', id)
        .set('price', price)
        .set('brand_id', brand)
        .set('page', page);

        return this.http.get<any>(this.apiUrl, { params });
      }else if(type_categorie==2){
        let params = new HttpParams()
        .set('second_category_id', id)
        .set('price', price)
        .set('brand', brand)
        .set('page', page);

        return this.http.get<any>(this.apiUrl, { params });
      }else{
        let params = new HttpParams()
        .set('third_category_id', id)
        .set('price', price)
        .set('brand', brand)
        .set('page', page);

        return this.http.get<any>(this.apiUrl, { params });
      }
  }
  getProductsSearch(page: number, keyword:string): Observable<any> {
    
    let params = new HttpParams()
    .set('keyword', keyword)
    .set('page', page);

    return this.http.get<any>(this.apiUrl, { params });
      
  }
  filtterProductsCategorie(minPrice: number, maxPrice: number, page: number, id:string, type_categorie:number): Observable<any> {
    
      if(type_categorie==1){
        let params = new HttpParams()
        .set('minPrice', minPrice)
        .set('maxPrice', maxPrice)
        .set('first_category_id', id)
        .set('page', page);

        return this.http.get<any>(this.apiUrl, { params });
      }else if(type_categorie==2){
        let params = new HttpParams()
        .set('minPrice', minPrice)
        .set('maxPrice', maxPrice)
        .set('second_category_id', id)
        .set('page', page);

        return this.http.get<any>(this.apiUrl, { params });
      }else{
        let params = new HttpParams()
        .set('minPrice', minPrice)
        .set('maxPrice', maxPrice)
        .set('third_category_id', id)
        .set('page', page);

        return this.http.get<any>(this.apiUrl, { params });
      }
  }

  getMaxPrice(): Observable<any> {
    return this.http.get<any>(this.maxPriceUrl);
  }
  getMaxPriceCategorie(id:string, type_categorie: number): Observable<any> {
    if(type_categorie==1){
      let params = new HttpParams()
        .set('first_category_id', id);
      return this.http.get<any>(this.maxPriceUrl);
    }else if(type_categorie==2){
      let params = new HttpParams()
        .set('second_category_id', id);
      return this.http.get<any>(this.maxPriceUrl);
    }else{
      let params = new HttpParams()
        .set('third_category_id', id);
      return this.http.get<any>(this.maxPriceUrl);
    }
  }

  getCategories(){
    let URL= URL_SERVICEIO+"/frontEnd/products/leftCategories";
    return this.http.get(URL);
  }
  getCategoriesDetail(slug:string){
    let URL= URL_SERVICEIO+"/frontEnd/products/categorie_detail/"+slug;
    //return this.http.get(URL);
    return this.http.get(URL).pipe(
      tap((resp: any) => {
        this.settingsService.settings$.subscribe((settings:any) => {
          const title_custom = resp.categorie?.title ? resp.categorie?.title : resp.categorie?.name;
          // Kiểm tra description, nếu product.description rỗng thì lấy setting.description
          const description_custom = resp.categorie?.description ? resp.categorie?.description : settings?.description;
          // Kiểm tra image, nếu product.image rỗng thì lấy setting.photo
          const imageUrl = resp.categorie?.image ? resp.categorie.image?.photo : settings?.logo;
          this.settingsService.updateMetaTags(title_custom, description_custom, imageUrl, settings.title);
        });
      })
    );
  }
  getCategoriesBrand(slug:string, brand:string){
    let URL= URL_SERVICEIO+"/frontEnd/products/categorie_brand/"+slug+"/"+brand;
    //return this.http.get(URL);
    return this.http.get(URL).pipe(
      tap((resp: any) => {
        this.settingsService.settings$.subscribe((settings:any) => {
          const title_custom = resp.categorie?.name+" - "+resp.brand?.name;
          // Kiểm tra description, nếu product.description rỗng thì lấy setting.description
          const description_custom = resp.categorie.description ? resp.categorie.description : settings?.description;
          // Kiểm tra image, nếu product.image rỗng thì lấy setting.photo
          const imageUrl = resp.categorie.image ? resp.categorie.image.photo : settings?.logo;
          this.settingsService.updateMetaTags(title_custom, description_custom, imageUrl, settings.title);
        });
      })
    );
  }

  showProduct(slug:string, id:string){
    let URL= URL_SERVICEIO+"/frontEnd/products/"+slug+"/"+id;
    //return this.http.get(URL);
    const PRODUCT_KEY = makeStateKey<any>('product-' + id);

    if (this.state.hasKey(PRODUCT_KEY)) {
      // Lấy dữ liệu từ TransferState, không gọi lại API
      const product = this.state.get(PRODUCT_KEY, null);
      return of(product);
    } else {
      return this.http.get(URL).pipe(
        tap((product: any) => {
          if (isPlatformBrowser(this.platformId)) {
            this.state.set(PRODUCT_KEY, product);
          }
          this.settingsService.settings$.subscribe((settings:any) => {
            const title_custom = product.product?.title ? product.product?.title : product.product?.name;
            // Kiểm tra description, nếu product.description rỗng thì lấy setting.description
            const description_custom = product.product?.description ? product.product?.description : settings?.description;
            // Kiểm tra image, nếu product.image rỗng thì lấy setting.photo
            const imageUrl = product.product?.image ? product.product?.image?.photo : settings?.logo;
            this.settingsService.updateMetaTags(title_custom, description_custom, imageUrl, settings.title);
          });
        })
      );
    }
  }

  
}
