import { Component, Inject, PLATFORM_ID } from '@angular/core';
import { SettingsService } from '../../services/settings.service';
import { isPlatformBrowser } from '@angular/common';
import { Title, Meta } from '@angular/platform-browser';
import { URL_WEBSITE } from '../../config/config';

@Component({
  selector: 'app-not-found',
  standalone: true,
  imports: [],
  templateUrl: './not-found.component.html',
  styleUrl: './not-found.component.css'
})
export class NotFoundComponent {
  title:string="404 page not found";
  settings:any=[];
  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    private titleService: Title,
    private metaService: Meta,
    private settingsService: SettingsService,
  ){
    this.settingsService.settings$.subscribe(
      (data) => {
        this.settings = data;
        this.updateMetaTags();
      },
      (error) => {
        console.error('Error fetching settings:', error);
      }
    );
  }

  private updateMetaTags(): void {
    if (isPlatformBrowser(this.platformId)) {
      const title = this.title || this.settings.title;
      const description = this.settings.description;
      const imageUrl = this.settings.photo;

      this.titleService.setTitle(title+ " - "+URL_WEBSITE);
      this.metaService.updateTag({ name: 'description', content: description });
      this.metaService.updateTag({ property: 'og:title', content: title });
      this.metaService.updateTag({ property: 'og:description', content: description });
      this.metaService.updateTag({ property: 'og:url', content: window.location.href });
      this.metaService.updateTag({ property: 'og:image', content: imageUrl });
      this.metaService.updateTag({ name: 'keywords', content: this.settings.keywords });
    }
  }
}
