import { ChangeDetectorRef, Component, Inject, PLATFORM_ID } from '@angular/core';
import { BrandService } from '../service/brand.service';
import { CommonModule, isPlatformBrowser } from '@angular/common';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { NgbPaginationModule } from '@ng-bootstrap/ng-bootstrap';
import { ProductService } from '../../product/service/product.service';
import { ImagesLazyloadModule } from '../../../shared/images-lazyload/images-lazyload.module';

@Component({
  selector: 'app-detail-brand',
  standalone: true,
  imports: [FormsModule, NgbPaginationModule, CommonModule, RouterModule, ImagesLazyloadModule],
  templateUrl: './detail-brand.component.html',
  styleUrl: './detail-brand.component.css'
})
export class DetailBrandComponent {
  products: any[] = [];
  settings:any=[];
  totalPages:number = 0;
  currentPage:number = 1;

  minValue: number = 50;
  maxValue: number = 80;
  maxLimit:number= 1000000;
  minLimit:number= 0;

  sliderKey!: string;
  brand_slug:string='';
  brand:any=[];
  brand_id:string='';
  categories:any=[];
  productSale:any=[];
  isContentExpanded: boolean = false;

  
  constructor(
    private brandService: BrandService,
    private productService: ProductService,
    private cdr: ChangeDetectorRef,
    public activedRoute: ActivatedRoute,
    private router: Router,
  ){}

  ngAfterViewInit() {
    // if (isPlatformBrowser(this.platformId)) {
    // this.initSlider();
    // }
    this.cdr.detectChanges();
  }
  ngOnInit(): void {
    this.activedRoute.params.subscribe((resp:any) => {
      this.brand_slug = resp.slug;
      this.brandService.getBrandsDetail(this.brand_slug).subscribe((response:any) => {
        this.brand=response.brand;
        this.brand_id=response.brand.id;
        this.getProducts(this.currentPage); 
      })
      this.activedRoute.queryParams.subscribe(params => {
        this.currentPage = params['page'] ? +params['page'] : 1;
        
      });
    })
    this.getCategories();
    // Lấy giá cao nhất của toàn bộ sản phẩm
    // this.brandService.getMaxPrice(this.brand_id).subscribe((maxPriceData: any) => {
    //   this.maxLimit = maxPriceData.maxPrice; // maxPrice từ API
    //   this.maxValue = maxPriceData.maxPrice/2;
    //   this.minValue = maxPriceData.minPrice;
    // });
  }
  getCategories(){
    this.productService.getCategories().subscribe((resp:any) =>{console.log(resp)
      this.categories=resp.categories;
      this.productSale=resp.productSale;
    })
  }
  showMore() {
    this.isContentExpanded = !this.isContentExpanded; // Đảo trạng thái
  }
  onPageChange(page: number): void {
    this.currentPage = page;
    this.getProducts(this.currentPage);
  }

  getProducts(page:number): void {
    this.brandService.getProductsBrand(page, this.brand.id).subscribe((data: any) => {
      this.products = data.products;
      this.totalPages = data.total;
    });
  }
  loadPage(page: number): void {
    this.currentPage = page;
  
    // Cập nhật URL với tham số page
    this.router.navigate([], {
      relativeTo: this.activedRoute,
      queryParams: { page: this.currentPage },
      queryParamsHandling: 'merge' // Giữ lại các query params khác nếu có
    });
  
    // Thực hiện tải dữ liệu hoặc các hành động khác cho trang mới
    this.getProducts(this.currentPage);
  }
}
