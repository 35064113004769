import { isPlatformBrowser } from '@angular/common';
import { Directive, ElementRef, Inject, Input, PLATFORM_ID, Renderer2 } from '@angular/core';

@Directive({
  // Tận dụng thuộc tính "loading" làm directive selector
  // Bạn có thể sử dụng tên gì khác cũng được
  selector: '[appLazyLoad]',
})
export class ImagesLazyloadDirective {

  @Input() appLazyLoad!: string;

  constructor(private el: ElementRef, private renderer: Renderer2, @Inject(PLATFORM_ID) private platformId: object) {}

  ngOnInit() {
    if (isPlatformBrowser(this.platformId)) {
      const observer = new IntersectionObserver((entries) => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            this.renderer.setAttribute(this.el.nativeElement, 'src', this.appLazyLoad);
            observer.unobserve(this.el.nativeElement);
          }
        });
      });

      observer.observe(this.el.nativeElement);
    }
  }
}