import { Component} from '@angular/core';
import { BrandService } from '../service/brand.service';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NgbPaginationModule } from '@ng-bootstrap/ng-bootstrap';
import { LeftProductComponent } from '../../product/layout/left-product/left-product.component';
import { SettingsService } from '../../../services/settings.service';

@Component({
  selector: 'app-list-brand',
  standalone: true,
  imports: [FormsModule, NgbPaginationModule, CommonModule,LeftProductComponent, RouterModule],
  templateUrl: './list-brand.component.html',
  styleUrl: './list-brand.component.css'
})
export class ListBrandComponent {
  title="Thương hiệu";
  settings:any=[];

  brands:any=[];
  totalPages:number = 0;
  currentPage:number = 1;

  constructor(
    private brandService: BrandService,
    private settingsService: SettingsService
  ){}
  ngOnInit(): void {
    //Called after the constructor, initializing input properties, and the first call to ngOnChanges.
    //Add 'implements OnInit' to the class.
    this.settingsService.settings$.subscribe((settings:any) => {
      this.settingsService.updateMetaTags("Thương hiệu ", settings.description, settings.logo, settings.title);
    });
    this.listBrands();
  }
  listBrands(page=1){
    this.brandService.getAllBrand(page).subscribe((resp:any) =>{
      this.brands=resp.brands;
      this.totalPages=resp.total;
      this.currentPage=page;
    })
  }
  loadPage($event:any){
    this.listBrands($event);
  }
}
